import { useQuery } from "@tanstack/react-query";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { VendorForm } from "../../../types/VendorForm";
import { Button } from "../../../components/reusable/Button";
import { Fragment, useState } from "react";
import { Modal } from "../../../components/reusable/Modal";
import { useNotification } from "../../../providers/NotificationContext";
import { Menu, Transition } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { DocumentTextIcon, PlusIcon } from "@heroicons/react/24/outline";
import { TextInput } from "../../../components/reusable/Form";

export const VendorFormList = () => {
  const { showSuccess, showError } = useNotification();
  const { currentOrganization } = useVendor();
  const [showAddVendorFormModal, setShowAddVendorFormModal] =
    useState(false);
  const [name, setName] = useState("");
  const [updatingVendorForm, setUpdatingVendorForm] = useState<number | null>(null);
  const { data, refetch } = useQuery({
    queryKey: ["vendorForms", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/vendor_forms`,
        params: {
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!currentOrganization,
  });

  const vendorForms: VendorForm[] = data?.data || [];
  const handleSubmit = () => {
    makeApiRequest({
      method: updatingVendorForm ? "PUT" : "POST",
      path: updatingVendorForm ? `/vendor/vendor_forms/${updatingVendorForm}` : `/vendor/vendor_forms`,
      params: {
        organization_id: currentOrganization?.id,
        name,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("Form created successfully");
        }
        if (res.status === 200) {
          showSuccess("Form updated successfully");
          setUpdatingVendorForm(null);
        }
        setName("");
        refetch();
      })
      .catch((err) => {
        const error = err.response.data;
        showError(error.message || "An error occurred");
      })
      .finally(() => {
        setShowAddVendorFormModal(false);
      });
  };

  const handleEditVendorFormClick = (id: number) => {
    setShowAddVendorFormModal(true);
    setUpdatingVendorForm(id);
    const vendorForm = vendorForms.find((pc) => pc.id === id);
    if (!vendorForm) {
      showError("Form not found");
      return;
    }
    
    setName(vendorForm.name);
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
          <DocumentTextIcon className="h-7 w-7" />
          Forms
        </h1>
        <Button
          onClick={() => {
            setShowAddVendorFormModal(true);
            setName("");
            setUpdatingVendorForm(null);
          }}
          variant="blue"
        >
          <PlusIcon className="h-5 w-5" />
          Add Form
        </Button>

      </div>
      <VendorCard className="mt-5">
        <VendorFormTable refetchCodes={refetch} handleEditVendorFormClick={handleEditVendorFormClick} vendorForms={vendorForms} />
      </VendorCard>

      <Modal
        isOpen={showAddVendorFormModal}
        title={updatingVendorForm ? "Edit Form" : "Add Form"}
        onRequestClose={() => setShowAddVendorFormModal(false)}
      >
        <div className="mt-6 flex flex-col items-center w-full">
          <div className="w-full max-w-md">
            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
              Form Name
            </label>
            <div className="flex items-center gap-2">
              <TextInput
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Button
                onClick={handleSubmit}
                variant="blue"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const VendorFormTable = ({
  vendorForms,
  handleEditVendorFormClick,
  refetchCodes
}: {
  refetchCodes: () => void;
  vendorForms: VendorForm[];
  handleEditVendorFormClick: (id: number) => void;
}) => {
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<VendorForm>();
  const { showSuccess, showError } = useNotification();
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
        header: "Name",
        cell: (info) => {
          return (
            <Link
              className="text-lightPurple hover:brightness-125"
              to={`${info.row.original.id}`}
            >
              {info.getValue()}
            </Link>
          );
        },
      }),
    columnHelper.accessor("form_field_count", {
      header: "Form Fields",
      cell: (info) => info.getValue(),  
    }),
    columnHelper.accessor("form_submission_count", {
      header: "Submissions",
      cell: (info) => info.getValue(),  
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-300" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-white shadow-md border-gray-200 border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-gray-100 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="block"
                      onClick={() => {
                        handleEditVendorFormClick(props.row.original.id);
                      }}
                    >
                      Edit
                    </span>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-gray-100 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="text-pink/60 block"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to permanently remove this form from your organization? This cannot be undone."
                          )
                        ) {
                          deleteVendorForm(props.row.original.id);
                        }
                      }}
                    >
                      Delete
                    </span>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: vendorForms,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const deleteVendorForm = (id: number) => {
    makeApiRequest({
      method: "DELETE",
      path: `/vendor/vendor_forms/${id}`,
      params: {
        organization_id: currentOrganization?.id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccess("Form deleted successfully");
          refetchCodes()
        }
      })
      .catch((err) => {
        const error = err.response.data;
        showError(error.message || "An error occurred");
      });
  }

  if (!vendorForms) {
    return <div>Loading...</div>;
  }

  if (vendorForms.length === 0) {
    return (
      <div className="flex justify-center items-center h-full">
        <p className="font-medium text-gray-600">
          No forms yet
        </p>
      </div>
    );
  }
  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="text-gray-500 text-sm" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="text-left" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr className="h-11 text-sm" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="py-1 text-gray-400" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
