import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Button } from "../../../components/reusable/Button";
import { ArrowUpTrayIcon, IdentificationIcon, PlusIcon } from "@heroicons/react/24/outline";
import { TextInput } from "../../../components/reusable/Form";
import { SeasonPass, SeasonPassGroup } from "../../../types/SeasonPass";
import { useNotification } from "../../../providers/NotificationContext";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Modal } from "../../../components/reusable/Modal";
import { dataURLtoBlob } from "../../../utils/images";
import { Spinner } from "../../../components/reusable/Spinner";

export const SeasonPassList = () => {
  const [showNewGroupInput, setShowNewGroupInput] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const { showSuccess, showError } = useNotification();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [searchExecuted, setSearchExecuted] = useState(false);
  const [searchQuery, setSearchQuery] = useState<{
    name: string;
    season_pass_group_id: string;
  } | null>(null);
  const [searchName, setSearchName] = useState("");
  const [searchGroupID, setSearchGroupID] = useState("");
  const { currentOrganization } = useVendor();
  const { data: seasonPassData, refetch: fetchSeasonPasses } = useQuery({
    queryKey: ["season_passes", currentOrganization, searchQuery],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/season_passes`,
        params: {
          organization_id: currentOrganization?.id,
          search: searchQuery,
        },
      }),
    enabled: !!searchQuery && !!currentOrganization,
    refetchOnWindowFocus: false,
  });

  const seasonPasses = seasonPassData?.data;

  const { data: seasonPassGroupsData, refetch: fetchSeasonPassGroups } =
    useQuery({
      queryKey: ["season_pass_groups", currentOrganization],
      queryFn: () =>
        makeApiRequest({
          path: `/vendor/season_pass_groups`,
          params: {
            organization_id: currentOrganization?.id,
          },
        }),
      enabled: !!currentOrganization,
    });

  const seasonPassGroups = seasonPassGroupsData?.data;

  const handleAddGroup = () => {
    makeApiRequest({
      path: "/vendor/season_pass_groups",
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        season_pass_group: {
          organization_id: currentOrganization?.id,
          name: newGroupName,
        },
      },
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("Group added successfully");
          setShowNewGroupInput(false);
          setNewGroupName("");
          fetchSeasonPassGroups();
        }
      })
      .catch((err) => {
        const errMessage = err.response.data.message;
        console.error(errMessage);
      });
  };

  const handleSearchClick = () => {
    setSearchExecuted(true);
    const newQuery = {
      name: searchName,
      season_pass_group_id: searchGroupID,
    };
    setSearchQuery(newQuery);
  };

  const clearSearch = () => {
    setSearchName("");
    setSearchGroupID("");
    setSearchExecuted(false);
    setSearchQuery(null);
  };

  const handleGroupClick = (groupId: string) => {
    setSearchGroupID(groupId);
    setSearchExecuted(true);
    setSearchQuery({
      name: searchName,
      season_pass_group_id: groupId,
    });
  };

  const columnHelper = createColumnHelper<SeasonPass>();

  const columns = [
    columnHelper.accessor("id", {
      header: "",
      cell: (info) => {
        return (
          <span>
            <IdentificationIcon className="w-7 h-7 mt-0.5 text-gray-500" />
          </span>
        );
      },
    }),
    columnHelper.accessor("user", {
      header: "Name",
      cell: (info) => {
        const user = info.getValue();
        return (
          <div>
            <span className="block">
              {user.first_name} {user.last_name}
            </span>
            <span className="text-sm text-gray-400">{user.email}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("season_pass_group", {
      header: "Group",
      cell: (info) => {
        const group = info.getValue();
        return (
          <div>
            <span className="block">{group.name}</span>
          </div>
        );
      },
    }),
    columnHelper.accessor("start_date", {
      header: "Starts",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("end_date", {
      header: "Ends",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("max_tickets_per_event", {
      header: "Tickets",
      cell: (info) => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: seasonPasses,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (searchQuery) {
      fetchSeasonPasses();
    }
  }, [searchQuery, fetchSeasonPasses]);

  return (
    <>
      <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
        <IdentificationIcon className="w-7 h-7" />
        Season Passes
      </h1>
      <div className="flex flex-col sm:flex-row gap-5 mt-5">
        <VendorCard className="w-full sm:w-1/3 p-5 bg-white shadow-lg rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <span className="font-semibold text-lg text-gray-700">Season Pass Groups</span>
            <Button
              onClick={() => setShowNewGroupInput(true)}
              variant="blue"
              size="sm"
            >
              <PlusIcon className="w-5 h-5" />
              Add Group
            </Button>
          </div>
          {(seasonPassGroups?.length === 0 && !showNewGroupInput) ? (
            <span className="block text-center py-5 font-medium text-gray-600">
              No groups yet
            </span>
          ) : (
            <div className="space-y-3">
              {seasonPassGroups?.map((group: SeasonPassGroup) => (
                <div
                  key={group.id}
                  className="p-3 rounded-md shadow-sm hover:bg-gray-100 transition-colors border border-gray-300 flex items-center gap-3 cursor-pointer"
                  onClick={() => handleGroupClick(group.id.toString())}
                >
                  <span>{group.name}</span>
                  <span className="bg-pink/10 text-pink font-bold px-3 text-sm rounded-full flex items-center justify-center">
                    {group.season_passes_count}
                  </span>
                </div>
              ))}
              {showNewGroupInput && (
                <div className="p-3 bg-gray-50 rounded-md border border-gray-300">
                  <TextInput
                    name="newGroup"
                    placeholder="New Group Name"
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    className="mb-3"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAddGroup();
                      }
                    }}
                  />
                  <div className="flex items-center justify-between">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={handleAddGroup}
                      className="mt-2"
                    >
                      Save
                    </Button>
                    <Button
                      className="ml-auto text-sm"
                      onClick={() => setShowNewGroupInput(false)}
                      variant="link"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </VendorCard>
  
        <VendorCard className="w-full sm:w-2/3">
          <div className="flex justify-between items-center">
            <span className="font-semibold text-lg text-gray-700">Season Passes</span>
            <Button
              onClick={() => setShowUploadModal(true)}
              variant="blue"
              size="sm"
            >
              <ArrowUpTrayIcon className="w-5 h-5" />
              Import Pass CSV
            </Button>
          </div>
  
          <div className="flex flex-col sm:flex-row gap-3 mt-5">
            <div className="w-full sm:w-2/3 relative">
              <TextInput
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                name="name"
                placeholder="Search Name"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchClick();
                  }
                }}
              />
              {searchExecuted && (searchName || searchGroupID) && (
                <span
                  onClick={clearSearch}
                  className="absolute -bottom-4 left-2 text-xs text-pink cursor-pointer"
                  role="button"
                >
                  Clear Search
                </span>
              )}
            </div>
            <div className="w-full sm:w-1/3">
              <select
                onChange={(e) => setSearchGroupID(e.target.value)}
                className="h-10 block w-full rounded bg-white text-gray-500 focus:ring-purple border border-gray-300 shadow-sm placeholder:text-gray-400"
                name="group"
                value={searchGroupID}
              >
                <option value="">Select Group</option>
                {seasonPassGroups?.map((group: SeasonPassGroup) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </select>
            </div>
            <Button onClick={handleSearchClick} variant="purple">
              Search
            </Button>
          </div>
  
          <div className="mt-8">
            {seasonPasses && seasonPasses.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200 text-sm">
                <thead>
                  {table.getHeaderGroups().map((headerGroup, i) => (
                    <tr className="text-gray-500" key={`${headerGroup.id}-${i}`}>
                      {headerGroup.headers.map((header, i) => (
                        <th className="text-left" key={`${header.id}-${i}`}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {table?.getRowModel()?.rows?.map((row, i) => (
                    <tr className="h-11" key={`${row.id}-${i}`}>
                      {row.getVisibleCells().map((cell, i) => (
                        <td
                          className="py-1 text-gray-500"
                          key={`${cell.id}-${i}`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-base mt-8 text-center p-4 font-medium text-gray-600">
                Please use the search fields above to display passes
              </p>
            )}
          </div>
        </VendorCard>
      </div>
      <UploadModal
        setSearchGroupID={setSearchGroupID}
        setSearchQuery={setSearchQuery}
        seasonPassGroups={seasonPassGroups}
        isOpen={showUploadModal}
        onRequestClose={() => setShowUploadModal(false)}
      />
    </>
  );
};

export const UploadModal = ({
  isOpen,
  onRequestClose,
  seasonPassGroups,
  setSearchQuery,
  setSearchGroupID,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  seasonPassGroups: SeasonPassGroup[];
  setSearchQuery: (query: {
    name: string;
    season_pass_group_id: string;
  }) => void;
  setSearchGroupID: (id: string) => void;
}) => {
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const { currentOrganization } = useVendor();
  const { showSuccess, showError } = useNotification();
  const [loading, setLoading] = useState(false);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    let uploadedFile;
    if (e.target.files) {
      uploadedFile = e.target.files[0];
    }

    if (uploadedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = reader.result;
        const blob: Blob = dataURLtoBlob(image as string);
        setFileBlob(blob);
      };
      reader.readAsDataURL(uploadedFile);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    console.log(fileBlob);
    formData.append("file", fileBlob as Blob);
    formData.append("season_pass_group_id", selectedGroup as string);
    console.log(formData);
    makeApiRequest({
      path: `/vendor/import_season_passes?organization_id=${currentOrganization?.id}`,
      method: "POST",
      params: formData,
      hasFormData: true,
    })
      .then((res) => {
        if (res.status === 200) {
          setSearchGroupID(selectedGroup || "");
          console.log(res.data);
          showSuccess("Season Passes imported successfully");
          onRequestClose();
          setSearchQuery({
            name: "",
            season_pass_group_id: selectedGroup || "",
          });
          setSelectedGroup(selectedGroup);
        }
      })
      .catch((err) => {
        const message = err.response.data.message;
        showError(message);
      })
      .finally(() => {
        setLoading(false);
        setFileBlob(null);
        setSelectedGroup(null);
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <>
        <h1 className="text-2xl font-semibold leading-6 text-gray-500">
          Import Season Passes
        </h1>
        <div className="my-5">
          <select
            onChange={(e) => setSelectedGroup(e.target.value)}
            className="h-10 block w-full rounded bg-white text-gray-500 focus:ring-purple border border-gray-300 shadow-sm placeholder:text-gray-400"
          >
            <option>Select Group</option>
            {seasonPassGroups?.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-5">
          <div className="mt-5">
            <input onChange={handleFileSelect} type="file" />
          </div>

          <div className="flex gap-4 items-center mt-8">
            <Button
              onClick={handleSubmit}
              disabled={!selectedGroup || !fileBlob || loading}
            >
              Import
            </Button>
            {loading && <Spinner size="sm" />}
          </div>
        </div>
      </>
    </Modal>
  );
};
