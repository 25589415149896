import { Route, Routes } from "react-router-dom"
import { VendorFormList } from "./VendorFormList"
import { ViewForm } from "./ViewForm"


export const VendorForms = () => {
  return (
    <Routes>
      <Route path="/" element={<VendorFormList />} />
      <Route path="/:formId" element={<ViewForm />} />
    </Routes>
  )
}