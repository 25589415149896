import { useQuery } from "@tanstack/react-query";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { EventPromoterCode, PromoterCode } from "../../../types/PromoterCode";
import { Button } from "../../../components/reusable/Button";
import { Fragment, useCallback, useState } from "react";
import { Modal } from "../../../components/reusable/Modal";
import { useNotification } from "../../../providers/NotificationContext";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { CheckCircleIcon, Cog6ToothIcon} from "@heroicons/react/24/solid";
import { Link, useParams } from "react-router-dom";
import { HappyEvent } from "../../../types/Event";
import { ListedGuest } from "../../../types/ListedGuest";
import { TextInput } from "../../../components/reusable/Form";
import { ArrowDownTrayIcon, CheckIcon, ClipboardDocumentCheckIcon, ListBulletIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { set } from "lodash";
import { saveAs } from "file-saver";
import { gu } from "date-fns/locale";
import { HappyTooltip } from "../../../components/reusable/Tooltip";
import { IoInformationCircle } from "react-icons/io5";

export const GuestList = ({ event }: { event: HappyEvent }) => {
  const params = useParams();
  const eventId = params.id;
  const { showSuccess, showError } = useNotification();
  const { currentOrganization } = useVendor();
  const [showGuestListModal, setShowGuestListModal] = useState(false);
  const [editingGuestId, setEditingGuestId] = useState<number | null>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [totalGuestCount, setTotalGuestCount] = useState<number>(1);

  const { data: guestListData, refetch: refetchGuestList } = useQuery({
    queryKey: ["eventGuestList", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/listed_guests`,
        params: {
          organization_id: currentOrganization?.id,
          event_id: eventId,
        },
      }),
    enabled: !!currentOrganization && !!eventId,
  });

  const guestList: ListedGuest[] = guestListData?.data || [];

  const sendApiRequest = () => {
    const url = editingGuestId ? `/vendor/listed_guests/${editingGuestId}` : "/vendor/listed_guests";
    const method = editingGuestId ? "PUT" : "POST";
    makeApiRequest({
      method,
      path: url,
      params: {
        organization_id: currentOrganization?.id,
        listed_guest: {
          event_id: eventId,
          first_name: firstName,
          last_name: lastName,
          total_guest_count: totalGuestCount,
        },
      },
    })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          const message = editingGuestId ? "Guest updated successfully" : "Guest created successfully";
          showSuccess(message);
          refetchGuestList();
          setShowGuestListModal(false);
          setEditingGuestId(null);
          setFirstName("");
          setLastName("");
          setTotalGuestCount(1);
        }
      })
      .catch((err) => {
        const error = err.response.data;
        showError(error.message || "An error occurred");
      });
  };

  const handleEditClick = (id: number) => {
    setEditingGuestId(id);
    const guest = guestList.find((g) => g.id === id);
    if (guest) {
      setFirstName(guest.first_name);
      setLastName(guest.last_name);
      setTotalGuestCount(guest.total_guest_count);
    }
    setShowGuestListModal(true);
  }

  const downloadGuestList = () => {
    makeApiRequest({
      path: `/vendor/events/${eventId}/event_guest_list.csv`,
      params: {
        organization_id: currentOrganization?.id
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `guest-list-event-${eventId}.csv`;
        saveAs(blob, filename);
      }
    });
  }

  return (
    <div>
      <VendorCard className="mt-5">
        <div className="flex items-center justify-between mb-5">
          <h2 className="font-bold">
            Guest List
            <HappyTooltip
              content="Add guests to the event without having to create a ticket for them. Guest list is available within the Happy Scanner app. Useful for VIPs, sponsors, and other special guests."
              anchor="right"
            >
              <IoInformationCircle className="h-5 w-5 -mb-1 text-gray-500" />
            </HappyTooltip>
          </h2>
          <div className="flex gap-2">
              {
                guestList.length > 0 && (
                  <Button onClick={downloadGuestList} size="sm">
                    <ArrowDownTrayIcon className="w-5 h-5" />
                    Download Guest List
                  </Button>
                )
              }
              
            <Button
              onClick={() => setShowGuestListModal(true)}
              variant="blue"
              size="sm"
            >
              <PlusIcon className="w-5 h-5" />
              Add Guest
            </Button>

          </div>
        </div>
        <GuestListTable
          handleEditClick={handleEditClick}
          event={event}
          refetchGuestList={refetchGuestList}
          guestList={guestList}
        />
      </VendorCard>

      <Modal
        isOpen={showGuestListModal}
        title="Add Guest to Event"
        onRequestClose={() => setShowGuestListModal(false)}
      >
        <>
          <div className="flex flex-col gap-4">
            <div>
              <TextInput
                label="First Name"
                placeholder="First Name"
                type="text"
                value={firstName}
                name="first_name"
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextInput
                label="Last Name"
                placeholder="Last Name"
                type="text"
                value={lastName}
                name="last_name"
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextInput
                label="Number of Tickets for Guest"
                placeholder="Number of Tickets"
                type="number"
                value={totalGuestCount}
                name="total_guest_count"
                onChange={(e) => setTotalGuestCount(parseInt(e.target.value))}
              />
            </div>
            <Button onClick={sendApiRequest} variant="blue" size="lg">
              {editingGuestId ? 'Update' : 'Create'} Guest
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export const GuestListTable = ({
  guestList,
  refetchGuestList,
  handleEditClick,
}: {
  event: HappyEvent;
  refetchGuestList: () => void;
  guestList: ListedGuest[];
  handleEditClick: (id: number) => void;
}) => {
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<ListedGuest>();
  const { showSuccess, showError } = useNotification();

  const columns = [
    columnHelper.accessor("full_name", {
      header: "Guest Name",
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.accessor("total_guest_count", {
      header: "Total Tickets",
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.accessor("checked_in", {
      header: "Checked In",
      cell: (info) => {
        return (
          <span>{info.getValue() ? <CheckIcon className="w-5 h-5 text-green-500" /> : <XMarkIcon className="w-5 h-5 text-red-500" />}</span>
        )
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <MenuButton className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-300" />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="w-40 right-0 absolute bg-white shadow-md border-gray-200 border z-10 rounded-md">
                <MenuItem
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-gray-100 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="text-pink/60 block"
                      onClick={() => {
                        if (props.row.original.id) {
                          handleEditClick(props.row.original.id)
                          return
                        }
                        showError("An error occurred, no guest id found.")
                      }}
                    >
                      Edit
                    </span>
                  </MenuItem>
                  <MenuItem
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-gray-100 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="text-pink/60 block"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this guest?"
                          )
                        ) {
                          deleteEventPromoterCode(props.row.original.id);
                        }
                      }}
                    >
                      Delete
                    </span>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: guestList,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const deleteEventPromoterCode = (id: number) => {
    makeApiRequest({
      method: "DELETE",
      path: `/vendor/listed_guests/${id}`,
      params: {
        organization_id: currentOrganization?.id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccess("Guest deleted successfully.");
          refetchGuestList();
        }
      })
      .catch((err) => {
        const error = err.response.data;
        showError(error.message || "An error occurred");
      });
  };

  if (!guestList) {
    return <div>Loading...</div>;
  }

  if (guestList.length === 0) {
    return  <>
              <div className="w-ful flex items-center justify-center">
                <div className="max-w-lg py-5 px-16 flex flex-col gap-2 justify-center items-center">
                  <div className="relative">
                    <ClipboardDocumentCheckIcon className="h-10 text-gray-300" />
                  </div>
                  <span className="text-xs font-bold">No Guest List Yet</span>
                </div>
              </div>
            </>;
  }
  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="text-gray-500 text-sm" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="text-left" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr className="h-11 text-sm" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="py-1 text-gray-400" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
