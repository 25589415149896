import { useQuery } from "@tanstack/react-query";
import { Payment } from "../../../types/Payment";

import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { VendorCard } from "../../../components/reusable/VendorCard";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { PaymentTable } from "../Orders/ShowOrder";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/react";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { TextInput } from "../../../components/reusable/Form";

type SearchFormQuery = {
  paymentId: string;
  paymentType: string;
  eventName: string;
  userName: string;
  dateFrom: Date | null;
  dateTo: Date | null;
};

export const PaymentList = () => {
  const { currentOrganization } = useVendor();
  const [paymentId, setPaymentId] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [error, setError] = useState<string>("");
  
  const [eventName, setEventName] = useState<string>("");
  const [userName, setUserName] = useState<string>("");

  const [searchQuery, setSearchQuery] = useState<SearchFormQuery | null>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSearchQuery({
      paymentId,
      paymentType,
      eventName,
      userName,
      dateFrom,
      dateTo,
    });
  };

  const handleClearSearch = () => {
    setPaymentId("");
    setDateFrom(null);
    setDateTo(null);
    setEventName("");
    setUserName("");
    setPaymentType("");
    setSearchQuery(null);
  };
  const { data } = useQuery({
    queryKey: ["payments", currentOrganization, searchQuery],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/payments",
        params: {
          organization_id: currentOrganization?.id,
          search: searchQuery,
        },
      }),
    enabled: !!currentOrganization,
  });

  const payments = data?.data;

  const downloadCSV = () => {
    makeApiRequest({
      path: `/vendor/payments.csv`,
      params: {
        organization_id: currentOrganization?.id,
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `payments-${format(new Date(), "MM/dd/yyyy")}.csv`;
        saveAs(blob, filename);
      }
    });
  };

  return (
    <>
      <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
        <CurrencyDollarIcon className="w-7 h-7" />
        Payments
      </h1>
      
      <VendorCard className="mt-4 mb-5 py-6">
        <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-0 sm:flex sm:items-center sm:gap-4">
          <TextInput
            name="paymentId"
            value={paymentId}
            onChange={(e) => setPaymentId(e.target.value)}
            placeholder="Payment ID"
            className="w-full "
          />

          <TextInput
            name="eventName"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            placeholder="Event Name"
            className="w-full"
          />

          <TextInput
            name="userName"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="User Name"
            className="w-full sm:flex-1"
          />

          <Menu as="div" className="relative inline-block text-left w-full sm:flex-1">
            <MenuButton className="flex justify-between items-center w-full focus:outline-purple focus:ring-0 bg-white h-10 px-3 rounded border border-gray-300">
              <span className="text-gray-400">
                {paymentType ? paymentType : "Type"}
              </span>
              <ChevronDownIcon className="h-5 ml-1" />
            </MenuButton>
            <MenuItems className="w-full right-0 absolute bg-white border-gray-300 border z-10 rounded-md">
              <MenuItem as="div" className="w-full">
                <button
                  className="block w-full text-left px-3 py-2 hover:bg-gray-200"
                  onClick={() => setPaymentType("sale")}
                >
                  Sale
                </button>
              </MenuItem>
              <MenuItem as="div" className="w-full">
                <button
                  className="block w-full text-left px-3 py-2 hover:bg-gray-200"
                  onClick={() => setPaymentType("refund")}
                >
                  Refund
                </button>
              </MenuItem>
            </MenuItems>
          </Menu>

          <div className="relative w-full sm:flex-2">
            <label className="text-xs absolute -top-5 text-gray-500 mt-1 sm:mt-0 ">From</label>
            <DatePicker
              placeholderText="Start Date"
              wrapperClassName="w-full"
              selected={dateFrom}
              onChange={(date) => setDateFrom(date as Date)}
              className="w-full h-10 block rounded bg-white text-gray-500 focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400"
            />
          </div>

          <div className="relative w-full sm:flex-2">
            <label className="text-xs absolute -top-5 text-gray-500 mt-1 sm:mt-0 ">To</label>
            <DatePicker
              placeholderText="End Date"
              wrapperClassName="w-full"
              selected={dateTo}
              onChange={(date) => setDateTo(date as Date)}
              className="w-full h-10 block rounded bg-white text-gray-500 focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400"
            />
          </div>

          <button
            className="h-10 bg-purple rounded-lg w-full sm:w-auto px-10 text-white"
            type="submit"
          >
            Search
          </button>

          {paymentId || eventName || userName || paymentType || dateFrom || dateTo ? (
            <button
              className="text-pink hover:brightness-125 text-xs block sm:absolute sm:left-0 sm:-bottom-4"
              onClick={handleClearSearch}
            >
              Clear Search
            </button>
          ) : null}

          <span className="text-yellow text-xs block sm:absolute sm:left-0 sm:-bottom-6">
            {error}
          </span>
        </form>
      </VendorCard>




      {payments && payments.length ? (
        <VendorCard>
          <div className="hidden sm:block">
            {/* Use PaymentTable for larger screens */}
            <span
              onClick={downloadCSV}
              role="button"
              className="block text-blue text-sm ml-auto text-right"
            >
              Download CSV
            </span>
            <PaymentTable payments={payments} />
          </div>
          <div className="block sm:hidden space-y-4">
            {payments?.map((payment: Payment) => (
              <div
                key={payment.id}
                className="relative p-4 border rounded-md shadow-sm"
              >
                <div className="flex justify-between">
                  <span className="font-medium text-gray-500">Payment ID:</span>
                  <Link
                    className="text-lightPurple hover:brightness-125"
                    to={`/vendor/organizations/${currentOrganization?.id}/payments/${payment.id}`}
                  >
                    {payment.id}
                  </Link>
                </div>
                <div className="flex justify-between">
                  <span className="font-medium text-gray-500">Event:</span>
                  <Link
                    className="text-lightPurple hover:brightness-125"
                    to={`/vendor/organizations/${currentOrganization?.id}/events/${payment.event?.id}`}
                  >
                    {payment.event?.name || "N/A"}
                  </Link>
                </div>
                <div className="flex justify-between">
                  <span className="font-medium text-gray-500">Order:</span>
                  <Link
                    className="text-lightPurple hover:brightness-125"
                    to={`/vendor/organizations/${currentOrganization?.id}/orders/${payment.order?.id}`}
                  >
                    #{payment.order?.id || "N/A"}
                  </Link>
                </div>
                <div className="flex justify-between">
                  <span className="font-medium text-gray-500">User:</span>
                  <Link
                    className="text-lightPurple hover:brightness-125"
                    to={`/vendor/organizations/${currentOrganization?.id}/customers/${payment.user?.id}`}
                  >
                    {payment.user?.first_name} {payment.user?.last_name}
                  </Link>
                </div>
                <div className="flex justify-between">
                  <span className="font-medium text-gray-500">Payment Type:</span>
                  <span>{payment.payment_type}</span>
                </div>
                <div className="flex justify-between">
                  <span className="font-medium text-gray-500">Amount:</span>
                  <span
                    className={
                      payment.payment_type === "Sale"
                        ? "text-green-400"
                        : "text-red-400"
                    }
                  >
                    ${((payment.amount_cents || 0) / 100).toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="font-medium text-gray-500">Created:</span>
                  <span>{payment.created_at}</span>
                </div>
              </div>
            ))}
          </div>
        </VendorCard>
      ) : (
        <VendorCard className="flex justify-center items-center h-40">
          <span className="font-medium text-gray-600">No payments yet</span>
        </VendorCard>
      )}
    </>
  );
};
