import { useQuery } from "@tanstack/react-query";
import { useVendor } from "../../../providers/VendorContext";
import { useEffect, useState } from "react";
import { makeApiRequest } from "../../../utils/api";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Button } from "../../../components/reusable/Button";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { saveAs } from "file-saver";
import { VendorFormData, FormFieldData, FormFieldAnswer } from "../../../types/Report";
import { useLocation } from "react-router-dom";



Chart.register(ArcElement, Tooltip, Legend);

export const VendorFormReport = () => {
  const { currentOrganization } = useVendor();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const preSelectedFormId = searchParams.get("vendor_form_id");
  const [selectedFormId, setSelectedFormId] = useState<string | null>(preSelectedFormId);

  // Fetch available vendor forms (dropdown options)
  const { data: formListData, isLoading: formListLoading, error: formListError } = useQuery({
    queryKey: ["vendor-form-list", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/vendor_form_report`,
      }),
    enabled: !!currentOrganization,
  });

  // Fetch report data only when a vendor form is selected
  const { data: formReportData, isLoading: reportLoading, error: reportError, refetch } = useQuery({
    queryKey: ["vendor-form-report", currentOrganization, selectedFormId],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/vendor_form_report`,
        params: { search: { vendor_form_id: selectedFormId } },
      }),
    enabled: !!currentOrganization && !!selectedFormId,
  });

  // Handle dropdown change & immediately fetch report
  const handleFormSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormId = event.target.value;
    setSelectedFormId(newFormId);
    refetch(); // Fetch report immediately after selection
  };

  // Download report CSV
  const downloadFormReport = () => {
    makeApiRequest({
      path: `/vendor/organizations/${currentOrganization?.id}/vendor_form_report.csv`,
      params: { organization_id: currentOrganization?.id, search: { vendor_form_id: selectedFormId } },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `form-analytics-${currentOrganization?.name}.csv`;
        saveAs(blob, filename);
      }
    });
  };

  const vendorFormData: VendorFormData = formReportData?.data;
  const vendorForms: { id: number; name: string }[] = formListData?.data?.vendor_forms || [];


  return (
    <div className="mt-3">

      {/* Form Selection Dropdown */}
      {formListLoading ? (
        <p>Loading available forms...</p>
      ) : formListError ? (
        <p>Error loading forms.</p>
      ) : (
        <div className="flex flex-col sm:flex-row gap-4 mt-4">

          <select
            id="vendorFormSelect"
            className="h-10 border rounded-lg px-4 bg-white w-full sm:w-1/4"
            onChange={handleFormSelect}
            value={selectedFormId || ""}
          >
            <option value="" disabled>
              Choose a form
            </option>
            {vendorForms.map((form: { id: number; name: string }) => (
              <option key={form.id} value={form.id}>
                {form.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Show message if no form is selected */}
      {!selectedFormId ? (
        <VendorCard className="mt-3 text-center">
          <p className="text-gray-600">Please select a form to view report data.</p>
        </VendorCard>
      ) : reportLoading ? (
        <VendorCard className="mt-3 text-center">
          <p>Loading form data...</p>
        </VendorCard>
      ) : reportError ? (
        <VendorCard className="mt-3 text-center">
          <p>Error loading report data.</p>
        </VendorCard>
      ) : (
        <>
          {/* Show total submissions */}
          <VendorCard className="mt-3 flex justify-between items-center">
            <p className="text-gray-600  font-bold">Total Submissions: {vendorFormData?.submissions_count}</p>
            <Button onClick={downloadFormReport} size="sm" className="mb-2 ml-auto">
              <ArrowDownTrayIcon className="w-5 h-5" />
              Download CSV
            </Button>
          </VendorCard>

          {/* Render field charts */}
          {vendorFormData?.form_field_data?.map((field: FormFieldData) => (
            <FormFieldChart key={field.field_id} field={field} />
          ))}
        </>
      )}
    </div>
  );
};

// Chart Component
const FormFieldChart = ({ field }: { field: FormFieldData }) => {
  const chartData = {
    labels: field.answers.map((answer: FormFieldAnswer) => answer.answer),
    datasets: [
      {
        data: field.answers.map((answer: FormFieldAnswer) => answer.percentage),
        backgroundColor: generateColors(field.answers.length),
        hoverOffset: 4,
      },
    ],
  };

  return (
    <VendorCard className='mt-3'>
      <h2 className="text-lg font-bold mb-4">{field.field_name}</h2>

      <div className="flex flex-col md:flex-row gap-6 items-center">
        {/* Pie Chart */}
        <div className="w-full md:w-1/3">
          <Pie data={chartData} />
        </div>

        {/* Table */}
        <div className="w-full md:w-2/3 overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 text-sm">
            <thead>
              <tr className="text-left">
                <th className="p-2">Answer</th>
                <th className="p-2">Count</th>
                <th className="p-2">Percentage</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {field.answers.map((answer: FormFieldAnswer, index: number) => (
                <tr key={index}>
                  <td className="p-2">{answer.answer}</td>
                  <td className="p-2">{answer.count}</td>
                  <td className="p-2">{answer.percentage}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </VendorCard>
  );
};

// Generate distinct colors for pie chart segments
const generateColors = (count: number) => {
  const colors = [
    "#7209B7",
    "#486BFF",
    "#F72685",
    "#E4C4FA",
    "#9966FF",
    "#FF9F40",
  ];
  return colors.slice(0, count);
};
