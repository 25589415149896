import { HappyEvent } from "../../../types/Event";
import { Fragment, ReactNode, useState } from "react";
import { Order, PriceInfo } from "../../../types/Order";
import { User } from "../../../types/User";
import { Menu, Transition } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import {
  Table,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Pagination } from "../../../components/reusable/Pagination";
import {
  formatCentsToDollars,
  formatCentsToReadableString,
} from "../../../utils/currency";
import { useVendor } from "../../../providers/VendorContext";
import { ArrowDownIcon, ArrowUpIcon, ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import { makeApiRequest } from "../../../utils/api";
import { saveAs } from "file-saver";
import { Resources } from "../../../types/Resources";
import { VendorTable } from "../../../components/reusable/VendorTable";
export const EventDetails = ({
  event,
  orders,
}: {
  event: HappyEvent;
  orders: Order[];
}) => {
  const stats = [
    { id: 1, name: "Tickets Sold", value: event.ticket_info?.sold || 0 },
    {
      id: 2,
      name: "Tickets Available",
      value: event.ticket_info?.available || 0,
    },
    {
      id: 3,
      name: "Total Revenue",
      value: formatCentsToDollars(event.total_revenue) || 0,
    },
  ];
  const first10Orders = orders?.length ? orders.slice(0, 10) : []
  return (
    <div className="p-4 rounded bg-white shadow-sm mt-5">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 border-b border-gray-200 py-3 sm:py-6">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center md:grid-cols-3">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4"
            >
              <dt className="text-base leading-7 text-gray-500">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-lightPurple sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <h3 className="font-bold text-gray-500 mt-5">Recent Orders</h3>
      <OrdersTable showCsvDownload={false} orders={first10Orders} />
    </div>
  );
};



export const OrdersTable = ({
  orders,
  showCsvDownload = true,
}: {
  showCsvDownload?: boolean;
  orders: Order[];
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<Order>();
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => (
        <Link
          className="text-lightPurple text-sm hover:underline"
          to={`/vendor/organizations/${currentOrganization?.id}/orders/${info.getValue()}`}
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("event", {
      header: "Event",
      cell: (info) => {
        const event: HappyEvent | undefined = info.renderValue() as HappyEvent | undefined;
        return (
          <Link
            className="text-lightPurple text-sm hover:brightness-125 hover:underline"
            to={`/vendor/organizations/${currentOrganization?.id}/events/${event?.id}`}
          >
            {event?.name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("customer_last_name", {
      header: "User",
      cell: (info) => {
        const user: User | undefined = info.row.original.user as User | undefined;
        return (
          <Link
            className="text-lightPurple text-sm hover:brightness-125 hover:underline"
            to={`/vendor/organizations/${currentOrganization?.id}/customers/${user?.id}`}
          >
            {user?.first_name} {user?.last_name}
          </Link>
        );
      },
    }),
    columnHelper.accessor("total_price_cents", {
      header: "Order Total",
      cell: (info) => <span className="text-sm">{formatCentsToDollars(info.getValue())}</span>,
    }),
    columnHelper.accessor("created_at", {
      header: "Created",
      cell: (info) => <span className="text-sm">{info.getValue()}</span>,
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <div className="flex justify-center">
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="inline-flex justify-center w-full rounded-md shadow-sm px-2 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
              <Cog6ToothIcon className="h-5 w-5 text-gray-400" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white z-20 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => downloadOrderReceipt(props.row.original.id)}
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } group flex items-center px-4 py-2 text-sm text-gray-700 w-full`}
                    >
                      <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
                      Order PDF
                    </button>
                  )}
                </Menu.Item>
                {/* Add more actions here if needed */}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      ),
    }),
  ];

  const downloadOrderReceipt = (order_id: string | number) => {
    makeApiRequest({
      path: `/orders/${order_id}/download_pdf`,
      method: "GET",
      responseType: 'blob',
    })
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `order_${order_id}_receipt.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading PDF:', error);
      });
  };

  const table = useReactTable<Order>({
    data: orders,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const downloadCSV = () => {
    makeApiRequest({
      path: `/vendor/orders.csv`,
      params: {
        organization_id: currentOrganization?.id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          const CSV = res.data;
          const blob = new Blob([CSV], { type: "text/csv" });
          const filename = `orders-${format(new Date(), "MM-dd-yyyy")}.csv`;
          saveAs(blob, filename);
        }
      })
      .catch((error) => {
        console.error('Error downloading CSV:', error);
      });
  };

  return (
    <>
      {showCsvDownload && orders?.length ? (
        <span
          onClick={downloadCSV}
          role="button"
          className="block text-blue text-sm ml-auto text-right cursor-pointer hover:underline"
        >
          Download CSV
        </span>
      ) : null}
      {orders && orders.length ? (
        <div className="space-y-4">
          {/* Responsive Table for larger screens */}
          <div className="hidden md:block overflow-x-auto">
            <VendorTable table={table as unknown as Table<Resources>} />
          </div>

          {/* Card Layout for small screens */}
          <div className="block md:hidden space-y-4">
            {orders.map((order) => (
              <div key={order.id} className="border rounded-lg p-4 shadow-sm space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-500 text-sm">{order.created_at}</span>
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                      <Cog6ToothIcon className="h-3 w-3 text-gray-400" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white z-20 ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => downloadOrderReceipt(order.id)}
                              className={`${
                                active ? 'bg-gray-100' : ''
                              } group flex items-center px-4 py-2 text-sm text-gray-700 w-full`}
                            >
                              <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
                              Order PDF
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-medium">ID:</span>
                  <Link
                    to={`/vendor/organizations/${currentOrganization?.id}/orders/${order.id}`}
                    className="text-lightPurple hover:brightness-125"
                  >
                    {order.id}
                  </Link>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-medium">Event:</span>
                  <Link
                    to={`/vendor/organizations/${currentOrganization?.id}/events/${order.event?.id}`}
                    className="text-lightPurple hover:brightness-125"
                  >
                    {order.event?.name}
                  </Link>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-medium">User:</span>
                  <Link
                    to={`/vendor/organizations/${currentOrganization?.id}/customers/${order.user?.id}`}
                    className="text-lightPurple hover:brightness-125"
                  >
                    {order.user?.first_name} {order.user?.last_name}
                  </Link>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-medium">Order Total:</span>
                  <span className="text-gray-700">
                    {formatCentsToDollars(order.total_price_cents)}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className="text-center py-3">No orders yet</p>
      )}
    </>
  );
};
