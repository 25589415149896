import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import {
  BuildingOffice2Icon,
  Cog6ToothIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/solid";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { FormEvent, Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useVendor } from "../../../providers/VendorContext";
import { Modal } from "../../../components/reusable/Modal";
import { Field, FieldInputProps, Form, Formik } from "formik";
import { Button } from "../../../components/reusable/Button";
import { useAuth } from "../../../providers/AuthContext";
import { OrgVenue, Venue } from "../../../types/Venue";
import { useNotification } from "../../../providers/NotificationContext";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { BuildingOfficeIcon } from "@heroicons/react/24/outline";

export const VenueList = () => {
  const [showAddVenueModal, setShowAddVenueModal] = useState<boolean>(false);
  const { currentOrganization } = useVendor();
  const { dispatch } = useNotification();
  const columnHelper = createColumnHelper<OrgVenue>();

  const { data, refetch } = useQuery({
    queryKey: ["org_venues", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: "/vendor/org_venues",
        params: { organization_id: currentOrganization?.id },
      }),
    enabled: !!currentOrganization,
    retry: false,
  });

  const org_venues = data?.data;

  const deleteOrgVenue = (id: number) => {
    makeApiRequest({
      path: `/vendor/org_venues/${id}`,
      params: { organization_id: currentOrganization?.id },
      method: "DELETE",
    })
      .then((res) => {
        if (res.status === 200) {
          refetch();
          dispatch({
            type: "open",
            payload: {
              level: "success",
              message: "Venue deleted successfully.",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("venue", {
      header: "Name",
      cell: (info) => {
        const venue: Venue | undefined = info.renderValue() as
          | Venue
          | undefined;
        return <span>{venue?.name}</span>;
      },
    }),
    columnHelper.accessor("venue", {
      header: "Description",
      cell: (info) => {
        const venue: Venue | undefined = info.renderValue() as
          | Venue
          | undefined;
        return <span>{venue?.description}</span>;
      },
    }),
    columnHelper.accessor("venue_address", {
      header: "Address",
      cell: (info) => info.getValue(),
    }),

    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-300" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-white shadow-md border-gray-200 border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="text-pink/60 block"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to remove this venue from your organization?"
                          )
                        ) {
                          deleteOrgVenue(props.row.original.id);
                        }
                      }}
                    >
                      Delete
                    </span>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: org_venues,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const handleSuccessfulAdd = () => {
    setShowAddVenueModal(false);
    dispatch({
      type: "open",
      payload: {
        level: "success",
        message: "Venue added successfully.",
      },
    });

    refetch();
  };

  return (
    <>
      <div className="">
        <div className="mx-auto">
          <div className="">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="flex flex-wrap items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                  <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center">
                    <BuildingOfficeIcon className="h-7 w-7" /> Venues
                  </h1>
                </div>
                <div className="mt-2 sm:mt-0">
                  <button
                    onClick={() => setShowAddVenueModal(true)}
                    type="button"
                    className="h-10 flex items-center rounded-md bg-blue px-3 py-2 text-sm text-white hover:bg-purple"
                  >
                    <PlusIcon className="h-5 mr-1" />
                    Add Venue
                  </button>
                </div>
              </div>

              <VendorCard className="mt-8">
                {org_venues && org_venues?.length > 0 ? (
                  <div className="flex flex-col gap-4">
                    {/* Table for Larger Screens */}
                    <div className="hidden sm:block">
                      <table className="min-w-full divide-y divide-gray-200 text-sm">
                        <thead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr className="text-gray-500 text-left" key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <th
                                  className="px-4 py-2"
                                  key={header.id}
                                >
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {table.getRowModel().rows.map((row) => (
                            <tr className="h-11 text-gray-500" key={row.id}>
                              {row.getVisibleCells().map((cell) => (
                                <td className="px-4 py-2" key={cell.id}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    {/* Cards for Mobile */}
                    <div className="sm:hidden flex flex-col gap-4">
                      {org_venues.map((venue: OrgVenue) => (
                        <div
                          key={venue.id}
                          className="relative p-4 border rounded-md shadow-sm"
                        >
                          <div className="flex justify-between items-center">
                            <h3 className="font-bold text-purple">{venue.venue?.name}</h3>
                            <Menu as="div" className="relative inline-block">
                              <Menu.Button className="bg-transparent flex items-center justify-center">
                                <Cog6ToothIcon className="h-6 text-gray-300" />
                              </Menu.Button>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 mt-2 w-64 bg-white shadow-md border-gray-200 border rounded-md z-10">
                                  <Menu.Item
                                    as="div"
                                    className="px-3 py-2 hover:bg-gray-50 cursor-pointer"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          "Are you sure you want to remove this venue from your organization?"
                                        )
                                      ) {
                                        deleteOrgVenue(venue.id);
                                      }
                                    }}
                                  >
                                    <span className="text-pink">Remove From Organization</span>
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                          <p className="text-sm text-gray-500 mt-2">
                            <b>Description:</b> {venue.venue?.description || "N/A"}
                          </p>
                          <p className="text-sm text-gray-500">
                            <b>Address:</b> {venue.venue_address || "N/A"}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <div className="max-w-lg py-5 px-4 flex flex-col gap-2 items-center text-center">
                      <div className="relative">
                        <BuildingOffice2Icon className="h-10 text-gray-300" />
                        <PlusCircleIcon className="h-5 text-gray-500 absolute right-0 bottom-0" />
                      </div>
                      <span className="text-sm font-bold text-gray-700">
                        No Venues Attached
                      </span>
                      <span className="text-xs text-gray-500">
                        Attach an existing venue or create a new venue in the Happy Ecosystem!
                      </span>
                      <Button
                        onClick={() => setShowAddVenueModal(true)}
                        variant="pink"
                        className="flex items-center gap-1 mt-5"
                      >
                        <PlusIcon className="w-4" /> Attach a Venue
                      </Button>
                    </div>
                  </div>
                )}
              </VendorCard>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={`Add Venue to ${currentOrganization?.name}`}
        isOpen={showAddVenueModal}
        onRequestClose={() => setShowAddVenueModal(false)}
      >
        <>
          <AddVenueModalContent onSuccessfulAdd={handleSuccessfulAdd} />
        </>
      </Modal>
    </>
  );
};

export const AddVenueModalContent = ({
  onSuccessfulAdd,
}: {
  onSuccessfulAdd: () => void;
}) => {
  const { user } = useAuth();
  const { showError } = useNotification();
  const { currentOrganization } = useVendor();
  const [name, setName] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<{ name: string } | null>(null);
  const { data, refetch, isFetching, isFetched } = useQuery({
    queryKey: ["venue_search", searchQuery],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/venue_search`,
        params: {
          organization_id: currentOrganization?.id,
          name: name,
        },
      }),
    enabled: !!searchQuery,
    retry: false,
  });

  const searchVenues = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name.trim()) {
      showError("Please enter a name");
      return;
    }
    setSearchQuery({ name });
  };

  const venues = data?.data;
  const createOrgVenue = ({ venue_id }: { venue_id: number }) => {
    makeApiRequest({
      path: `/vendor/org_venues`,
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        org_venue: {
          organization_id: currentOrganization?.id,
          venue_id,
        },
      },
    })
      .then((res) => {
        if (res.status === 201) {
          onSuccessfulAdd();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="flex flex-col py-3">
      <form onSubmit={searchVenues} className="w-full">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <input
              placeholder="Find a Venue"
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              className="rounded-lg border border-gray-300 p-2 flex-grow"
            />
            <Button variant="blue" type="submit" className="flex-shrink">
              Search
            </Button>
          </div>
        </div>
      </form>

      {venues && venues?.length ? (
        <div className="flex flex-col mt-5 w-full">
          {venues.map((venue: Venue) => {
            const isAttached = venue?.attached_organization_ids.includes(
              currentOrganization?.id || 0
            );
            console.log(venue.id);
            return (
              <div
                className="flex justify-between items-center py-3 border-b text-gray-500"
                key={venue.id}
              >
                <span className="block w-1/2 text-sm font-bold">
                  {venue.name}
                  <br />
                  <span className="block text-xs font-normal">
                    {/* ADD CITY STATE HERE */}
                  </span>
                </span>
                {isAttached ? (
                  <span>Added!</span>
                ) : (
                  <Button
                    size="sm"
                    variant={"success"}
                    onClick={() => createOrgVenue({ venue_id: venue.id })}
                  >
                    Add Venue
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-">
          {isFetched ? (
            <>
              <span className="text-white block my-3 bg-lightPurple p-3 rounded-lg text-center m-t-3">
                No venues found with that name. Use the form below to create a
                new venue for your organization.
              </span>
              <NewVenueForm initialName={name} onSuccessfulAdd={onSuccessfulAdd} />
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export const NewVenueForm = ({
  onSuccessfulAdd,
  initialName
}: {
  onSuccessfulAdd: (venue: Venue) => void;
  initialName?: string;
}) => {
  const {user} = useAuth();
  const { currentOrganization } = useVendor();
  const {showError} = useNotification();
  return (
    <Formik
      onSubmit={(data) => {
        const payload = {
          user_id: user?.id,
          organization_id: currentOrganization?.id,
          add_venue: {
            ...data,
          },
        };

        makeApiRequest({
          path: `/vendor/venues`,
          method: "POST",
          params: payload,
        })
          .then((res) => {
            if (res.status === 201) {
              const { data: orgVenue } = res;
              const venue = orgVenue.venue;
              onSuccessfulAdd(venue);
            }
          })
          .catch(({ response }) => {
            const { data } = response;
            showError(data.errors);
          });
      }}
      initialValues={{
        name: initialName || "",
        description: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zip: "",
        phone_number: "",
        country: "US",
      }}
    >
      <NewVenueFormContent />
    </Formik>
  );
};

const NewVenueFormContent = () => {
  return (
    <Form className="p-3 bg-green-400/10 rounded-lg">
      <div className="flex flex-wrap gap-5">
        <Field name="name">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="w-full">
              <label className="block my-1 font-bold text-sm text-gray-500">
                Venue Name
              </label>
              <input
                className="focus:ring-purple focus:border-purple bg-white border border-gray-200 rounded-lg w-full"
                type="text"
                {...field}
              />
            </div>
          )}
        </Field>

        <Field name="description">
          {({ field }: { field: FieldInputProps<never> }) => (
            <div className="w-full">
              <label className="block my-1 font-bold text-sm text-gray-500">
                Venue Description
              </label>
              <textarea
                className="focus:ring-purple focus:border-purple bg-white border border-gray-200 rounded-lg w-full"
                rows={3}
                {...field}
              />
            </div>
          )}
        </Field>
        <div className="w-full flex gap-3">
          <Field name="address_1">
            {({ field }: { field: FieldInputProps<never> }) => (
              <div className="w-1/2">
                <label className="block my-1 font-bold text-sm text-gray-500">
                  Address
                </label>
                <input
                  className="focus:ring-purple focus:border-purple bg-white border border-gray-200 rounded-lg w-full"
                  type="text"
                  {...field}
                />
              </div>
            )}
          </Field>

          <Field name="address_2">
            {({ field }: { field: FieldInputProps<never> }) => (
              <div className="w-1/2">
                <label className="block my-1 font-bold text-sm text-gray-500">
                  Line 2
                </label>
                <input
                  className="focus:ring-purple focus:border-purple bg-white border border-gray-200 rounded-lg w-full"
                  type="text"
                  {...field}
                />
              </div>
            )}
          </Field>
        </div>
        <div className="flex justify-between gap-3 w-full">
          <Field name="city">
            {({ field }: { field: FieldInputProps<never> }) => (
              <div className="w-1/2">
                <label className="block my-1 font-bold text-sm text-gray-500">
                  City
                </label>
                <input
                  className="focus:ring-purple focus:border-purple bg-white border border-gray-200 rounded-lg w-full"
                  type="text"
                  {...field}
                />
              </div>
            )}
          </Field>
          <Field name="state">
            {({ field }: { field: FieldInputProps<never> }) => (
              <div className="w-1/2">
                <label className="block my-1 font-bold text-sm text-gray-500">
                  State
                </label>
                <input
                  className="focus:ring-purple focus:border-purple bg-white border border-gray-200 rounded-lg w-full"
                  type="text"
                  {...field}
                />
              </div>
            )}
          </Field>
        </div>

        <div className="w-full flex justify-between gap-3">
          <Field name="zip">
            {({ field }: { field: FieldInputProps<never> }) => (
              <div className="w-1/2">
                <label className="block my-1 font-bold text-sm text-gray-500">
                  Zip
                </label>
                <input
                  className="focus:ring-purple focus:border-purple bg-white border border-gray-200 rounded-lg w-full"
                  type="text"
                  {...field}
                />
              </div>
            )}
          </Field>

          <Field name="phone_number">
            {({ field }: { field: FieldInputProps<never> }) => (
              <div className="w-1/2">
                <label className="block my-1 font-bold text-sm text-gray-500">
                  Phone Number
                </label>
                <input
                  className="focus:ring-purple focus:border-purple border-gray-200 bg-white rounded-lg border w-full"
                  type="text"
                  {...field}
                />
              </div>
            )}
          </Field>
        </div>

        <Button variant="success" type="submit">
          Add Venue
        </Button>
      </div>
    </Form>
  );
};
