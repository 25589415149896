import { useQuery } from "@tanstack/react-query";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { HappyTooltip } from "../../../components/reusable/Tooltip";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { PromoterCode } from "../../../types/PromoterCode";
import { Button } from "../../../components/reusable/Button";
import { Fragment, useState } from "react";
import { Modal } from "../../../components/reusable/Modal";
import { useNotification } from "../../../providers/NotificationContext";
import { Menu, Transition } from "@headlessui/react";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import { id } from "date-fns/locale";
import { set } from "lodash";
import { MegaphoneIcon } from "@heroicons/react/24/outline";
import { IoInformationCircle } from "react-icons/io5";

export const PromoterCodes = () => {
  const { showSuccess, showError } = useNotification();
  const { currentOrganization } = useVendor();
  const [showAddPromoterCodeModal, setShowAddPromoterCodeModal] =
    useState(false);
  const [code, setCode] = useState("");
  const [promoter, setPromoter] = useState("");
  const [updatingPromoterCode, setUpdatingPromoterCode] = useState<number | null>(null);
  const { data, refetch } = useQuery({
    queryKey: ["promoterCodes", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/promoter_codes`,
        params: {
          organization_id: currentOrganization?.id,
        },
      }),
    enabled: !!currentOrganization,
  });

  const promoterCodes: PromoterCode[] = data?.data || [];
  const handleSubmit = () => {
    makeApiRequest({
      method: updatingPromoterCode ? "PUT" : "POST",
      path: updatingPromoterCode ? `/vendor/promoter_codes/${updatingPromoterCode}` : `/vendor/promoter_codes`,
      params: {
        organization_id: currentOrganization?.id,
        code,
        promoter,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess("Promoter code created successfully");
        }

        if (res.status === 200) {
          showSuccess("Promoter code updated successfully");
          setShowAddPromoterCodeModal(false);
          setUpdatingPromoterCode(null);
        }
        setCode("");
        setPromoter("");
        refetch();
      })
      .catch((err) => {
        const error = err.response.data;
        showError(error.message || "An error occurred");
      });
  };

  const handleEditPromoterCodeClick = (id: number) => {
    setShowAddPromoterCodeModal(true);
    setUpdatingPromoterCode(id);
    const promoterCode = promoterCodes.find((pc) => pc.id === id);
    if (!promoterCode) {
      showError("Promoter code not found");
      return;
    }
    setCode(promoterCode.code);
    setPromoter(promoterCode.promoter);
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
          <MegaphoneIcon className="h-7 w-7" />
          Promoter Codes
          <HappyTooltip
            content="Promoter codes are used to track sales made by promoters. Each promoter code is unique and can be added to any event to generate a link that promoters can share."
            anchor="bottom"
          >
            <IoInformationCircle className="h-5 w-5 -mb-1 text-gray-500" />
          </HappyTooltip>
        </h1>
        <Button
          onClick={() => setShowAddPromoterCodeModal(true)}
          variant="blue"
        >
          Add Promoter Code
        </Button>
      </div>
      <VendorCard className="mt-5">
        <PromoterCodeTable refetchCodes={refetch} handleEditPromoterCodeClick={handleEditPromoterCodeClick} promoterCodes={promoterCodes} />
      </VendorCard>

      <Modal
        isOpen={showAddPromoterCodeModal}
        title="Add Promoter Code to Organization"
        onRequestClose={() => setShowAddPromoterCodeModal(false)}
      >
        <>
          <div className="flex gap-4 mt-5 items-end">
            <div className="flex flex-col">
              <label htmlFor="code">
                Code
                <HappyTooltip
                  content="Enter a unique code that will be used by promoters to track their sales"
                  anchor="top"
                >
                  <IoInformationCircle className="h-5 w-5 -mb-1 text-gray-500" />
                </HappyTooltip>
              </label>
              <input
                placeholder="Enter code"
                id="code"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="w-full rounded-lg border-gray-300 mt-2"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="promoter">Promoter Name</label>
              <input
                placeholder="Enter promoter"
                id="promoter"
                type="text"
                value={promoter}
                onChange={(e) => setPromoter(e.target.value)}
                className="w-full rounded-lg border-gray-300 mt-2"
              />
            </div>
            <Button onClick={handleSubmit} variant="purple" className="mt-2">
              Save
            </Button>
          </div>
        </>
      </Modal>

    </div>
  );
};

export const PromoterCodeTable = ({
  promoterCodes,
  handleEditPromoterCodeClick,
  refetchCodes
}: {
  refetchCodes: () => void;
  promoterCodes: PromoterCode[];
  handleEditPromoterCodeClick: (id: number) => void;
}) => {
  const { currentOrganization } = useVendor();
  const columnHelper = createColumnHelper<PromoterCode>();
  const { showSuccess, showError } = useNotification();
  const columns = [
    columnHelper.accessor("id", {
      header: "Item ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("code", {
      header: "Code",
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.accessor("promoter", {
      header: "Promoter",
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.accessor("total_orders", {
      header: "Total Orders",
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.accessor("total_tickets", {
      header: "Total Tickets",
      cell: (info) => {
        return <span>{info.getValue()}</span>;
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-gray-300" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-white shadow-md border-gray-200 border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-gray-100 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="block"
                      onClick={() => {
                        handleEditPromoterCodeClick(props.row.original.id);
                      }}
                    >
                      Edit
                    </span>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-gray-100 border-gray-200 border-t"
                    }
                  >
                    <span
                      role="button"
                      className="text-pink/60 block"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to remove this promoter code from your organization?"
                          )
                        ) {
                          deletePromoterCode(props.row.original.id);
                        }
                      }}
                    >
                      Delete
                    </span>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: promoterCodes,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const deletePromoterCode = (id: number) => {
    makeApiRequest({
      method: "DELETE",
      path: `/vendor/promoter_codes/${id}`,
      params: {
        organization_id: currentOrganization?.id,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showSuccess("Promoter code deleted successfully");
          refetchCodes()
        }
      })
      .catch((err) => {
        const error = err.response.data;
        showError(error.message || "An error occurred");
      });
  }

  if (!promoterCodes) {
    return <div>Loading...</div>;
  }

  if (promoterCodes.length === 0) {
    return (
      <div className="flex justify-center items-center h-full">
        <p className="font-medium text-gray-600">
          No promoter codes yet
        </p>
      </div>
    );
  }
  return (
    <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="text-gray-500 text-sm" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th className="text-left" key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200">
          {table?.getRowModel()?.rows?.map((row) => {
            return (
              <tr className="h-11 text-sm" key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td className="py-1 text-gray-400" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
