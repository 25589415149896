import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { Organization } from "../../../types/Organization";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import DatePicker from "react-datepicker";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Fragment, ReactNode, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/20/solid";
import { HappyEvent, PriceLevel, Section, Tier } from "../../../types/Event";
import { User } from "../../../types/User";
import { format } from "date-fns";
import { isEqual, set } from "lodash";
import { Spinner } from "../../../components/reusable/Spinner";
type SearchFormQuery = {
  email: string;
  name: string;
  level: number | null;
  gender: string | null;
};
type GenderDisplayMap = {
  [key: string]: string;
};

type LevelDisplayMap = {
  [key: string]: string;
};
export const Users = () => {
  // search values
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [gender, setGender] = useState<string | null>(null);
  const [level, setLevel] = useState<number | null>(null);

  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<SearchFormQuery>();

  const columnHelper = createColumnHelper<User>();
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["users", searchQuery],
    queryFn: () =>
      makeApiRequest({
        path: "/admin/users",
        params: { search: searchQuery },
      }),
    enabled: true,
    retry: false,
  });

  const users: User[] = data?.data;
  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("full_name", {
      header: "Name",
      cell: (info) => {
        return (
          <Link className="text-lightPurple hover:brightness-125" to="">
            {info.getValue()}
          </Link>
        );
      },
    }),
    columnHelper.accessor("email", {
      header: "Email",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("phone_number", {
      header: "Phone",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("gender", {
      header: "Gender",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("dob", {
      header: "DOB",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("created_at", {
      header: "Created",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <Menu.Button className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-white/30" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
                  <Menu.Item
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/admin/users/${props.row.original.id}`}
                    >
                      View
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 border-borderAdmin border-t"
                    }
                  >
                    <Link
                      className="text-pink/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Delete
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleSubmit = () => {
    if (!userName && !userEmail && !level && !gender) {
      setError("Please enter a something.");
      return;
    }
    setError("");
    const payload: SearchFormQuery = {
      name: userName,
      email: userEmail,
      gender: gender,
      level: level,
    };
    if (isEqual(payload, searchQuery)) {
      refetch();
      return;
    }
    setSearchQuery(payload);
  };

  const handleClearSearch = () => {
    setError("");
    setUserName("");
    setUserEmail("");
    setGender(null);
    setLevel(null);
    setSearchQuery(undefined);
  };

  

  const genderDisplayMap: GenderDisplayMap = {
    "m": 'Male',
    "f": 'Female'
  };

  return (
    <>
      <div className="">
        <div className="mx-auto">
          <div className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-white/60">
                    Users
                  </h1>
                </div>
                <button
                  type="button"
                  className="h-10 -translate-y-full sm:translate-y-0 flex items-center rounded-md bg-pink px-3 py-2 text-center text-sm font-semibold text-white hover:bg-purple"
                >
                  <PlusIcon className="h-5 mr-1" />
                  Create User
                </button>
              </div>
              <div className="mt-4 px-6 py-8 rounded-lg border-borderAdmin border flex justify-center items-center">
                <div className="flex items-center relative w-full justify-center gap-2">
                  <input
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Name"
                    className="focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                  />
                  { searchQuery && (
                    <button
                      className="text-pink hover:brightness-125 absolute left-20 text-xs -bottom-6"
                      onClick={handleClearSearch}
                    >
                      Clear Search
                    </button>
                  )}
                  <input
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    placeholder="Email"
                    className="focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                  />
                  <div className="relative w-32">
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full"
                    >
                      <Menu.Button className="flex justify-between items-center w-full focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none">
                        <span>{level || "Level"}</span>
                        <ChevronDownIcon className="h-5 ml-1" />
                      </Menu.Button>
                      <Menu.Items className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
                        <Menu.Item>
                          <button
                            className="block px-3 py-2 text-white/60"
                            onClick={() => setLevel(1)}
                          >
                            Scanner
                          </button>
                        </Menu.Item>
                        {/* <Menu.Item>Admin</Menu.Item>
                        <Menu.Item>Somethin Else</Menu.Item> */}
                      </Menu.Items>
                    </Menu>
                  </div>
                  <div className="relative w-32">
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-full"
                    >
                      <Menu.Button className="flex justify-between items-center w-full focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none">
                        <span>{gender ? genderDisplayMap[`${gender}`] : "Gender"}</span>
                        <ChevronDownIcon className="h-5 ml-1" />
                      </Menu.Button>
                      <Menu.Items className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
                        <Menu.Item>
                          <button
                            className="block px-3 py-2 text-white/60"
                            onClick={() => setGender("m")}
                          >
                            Male
                          </button>
                        </Menu.Item>
                        <Menu.Item>
                          <button
                            className="block px-3 py-2 text-white/60"
                            onClick={() => setGender("f")}
                          >
                            Female
                          </button>
                        </Menu.Item>
                        {/* <Menu.Item>Admin</Menu.Item>
                        <Menu.Item>Somethin Else</Menu.Item> */}
                      </Menu.Items>
                    </Menu>
                  </div>
                  <div className="relative over">
                    <button
                      type='submit'
                      className="h-10 bg-purple rounded-lg px-10 text-white"
                      onClick={handleSubmit}
                    >
                      {isFetching ? <Spinner /> : `Search`}
                    </button>
           
                  </div>
                    <span className="text-yellow absolute block text-center text-xs -bottom-6 m-auto">
                      {error}
                    </span>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 overflow-visible">
                  <div className="overflow-visible inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    {users && searchQuery ? (
                      <table className="min-w-full divide-y divide-borderAdmin">
                        <thead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr className="text-white/50" key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <th className="text-left" key={header.id}>
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody className="divide-y divide-borderAdmin">
                          {table?.getRowModel()?.rows?.map((row) => {
                            return (
                              <tr className="h-11" key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td
                                      className="py-1 text-white/30"
                                      key={cell.id}
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
