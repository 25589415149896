import { useQuery } from "@tanstack/react-query";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { ChartPieIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState, useMemo } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { VendorReport } from "../../../types/Report";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Option } from "../Events/Reports";
import { SalesByMonth } from "./SalesByMonth";
import { SalesByEvent } from "./SalesByEvent";
import { SalesByCategory } from "./SalesByCategory";
import { SalesByPaymentMethod } from "./SalesByPaymentMethod";
import { SalesBySalesChannel } from "./SalesBySalesChannel";
import { ProductSalesByEvent } from "./ProductSalesByEvent";
import { SeasonPassReport } from "./SeasonPassReport";
import { TaxReport } from "./TaxReport";
import { SalesByCode } from "./SalesByCode";
import { VendorFormReport } from "./VendorFormReport";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const VendorReports = () => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const { currentOrganization } = useVendor();
  const location = useLocation();

  const options = useMemo(() => [
    {
      label: "Sales By Month",
      href: `/vendor/organizations/${currentOrganization?.id}/reports`,
    },
    {
      label: "Sales by Event",
      href: `/vendor/organizations/${currentOrganization?.id}/reports/sales-by-event`,
    },
    {
      label: "Sales by Category",
      href: `/vendor/organizations/${currentOrganization?.id}/reports/sales-by-category`,
    },
    {
      label: "Sales by Code",
      href: `/vendor/organizations/${currentOrganization?.id}/reports/sales-by-code`,
    },
    {
      label: "Sales by Payment Method",
      href: `/vendor/organizations/${currentOrganization?.id}/reports/sales-by-payment-method`,
    },
    {
      label: "Sales by Channel",
      href: `/vendor/organizations/${currentOrganization?.id}/reports/sales-by-channel`,
    },
    {
      label: "Vendor Form Report",
      href: `/vendor/organizations/${currentOrganization?.id}/reports/vendor-form-report`,
    },
    {
      label: "Product Sales Breakdown",
      href: `/vendor/organizations/${currentOrganization?.id}/reports/product-sales-by-event`,
    },
    {
      label: "Season Pass Report",
      href: `/vendor/organizations/${currentOrganization?.id}/reports/season-pass-report`,
    },
    ...(currentOrganization?.add_tax_at_checkout
      ? [
        {
          label: "Tax Report",
          href: `/vendor/organizations/${currentOrganization?.id}/reports/tax-report`,
        },
      ]
      : []),
  ], [currentOrganization]);



  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ["vendorReports", currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/reports`,
      }),
    enabled: !!currentOrganization,
    retry: false,
  });


  const handleOptionSelect = (option: Option) => {
    setSelectedOption(option);
    navigate(option.href);
  };

  useEffect(() => {
    const activeOption = options.find((option) => option.href === location.pathname);
    if (activeOption) {
      setSelectedOption(activeOption);
    }
  }, [location.pathname, options]);

  const report: VendorReport = data?.data;
  if (!report) {
    return null;
  }

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
          <ChartPieIcon className="w-7 h-7" />
          Reports
        </h1>
        <Menu as="div" className="max-w-lg relative inline-block text-left z-50">
          <div>
            <MenuButton className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500">
              {selectedOption ? selectedOption.label : "Select a Report"}
              <ChevronDownIcon
                className="w-5 h-5 ml-2 -mr-1 text-gray-400 hover:text-violet-100"
                aria-hidden="true"
              />
            </MenuButton>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
              <div className="px-1 py-1">
                {options.map((option) => (
                  <MenuItem key={option.label}>
                    {({ focus }) => (
                      <button
                        className={`${focus ? "bg-gray-100 text-gray-900" : "text-gray-700"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option.label}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      </div>

      <Routes>
        <Route path="/" element={<SalesByMonth report={report} />} />
        <Route path="/sales-by-event" element={<SalesByEvent />} />
        <Route path="/sales-by-category" element={<SalesByCategory />} />
        <Route path="/sales-by-code" element={<SalesByCode />} />
        <Route
          path="/sales-by-payment-method"
          element={<SalesByPaymentMethod />}
        />
        <Route path="/sales-by-channel" element={<SalesBySalesChannel />} />
        <Route path="/vendor-form-report" element={<VendorFormReport />} />
        <Route path="/product-sales-by-event" element={<ProductSalesByEvent />} />
        <Route path="/season-pass-report" element={<SeasonPassReport />} />
        <Route path="/tax-report" element={<TaxReport />} />
      </Routes>
    </>
  );
};

