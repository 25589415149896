// src/utils/sanitizeHTML.ts

export const sanitizeHTML = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Remove disallowed tags
  const forbiddenTags = ['script', 'iframe', 'object', 'embed', 'style', 'link'];
  forbiddenTags.forEach((tag) => {
    const elements = doc.querySelectorAll(tag);
    elements.forEach((el) => el.remove());
  });

  // Allowed tags and their attributes
  const allowedTags: { [key: string]: string[] } = {
    a: ['href', 'title', 'target', 'rel'],
    ul: [],
    ol: [], 
    li: [], 
    // Add other tags and their allowed attributes as needed
  };

  const elements = doc.querySelectorAll('*');
  elements.forEach((el) => {
    const tagName = el.tagName.toLowerCase();

    // Remove all attributes not allowed for the tag
    Array.from(el.attributes).forEach((attr) => {
      if (
        !(
          allowedTags[tagName] &&
          allowedTags[tagName].includes(attr.name.toLowerCase())
        )
      ) {
        el.removeAttribute(attr.name);
      }
    });

    if (tagName === 'ul') {
      el.classList.add('custom-ul');
    }
    if (tagName === 'ol') {
      el.classList.add('custom-ol');
    }

    // Optionally, validate href protocols in <a> tags
    if (tagName === 'a' && el.getAttribute('href')) {
      const href = el.getAttribute('href') || '';
      const allowedProtocols = ['http:', 'https:', 'mailto:'];
      try {
        const url = new URL(href, window.location.href);
        if (!allowedProtocols.includes(url.protocol)) {
          el.removeAttribute('href');
        }
      } catch {
        el.removeAttribute('href');
      }
    }
  });

  return doc.body.innerHTML;
};
