import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../utils/api";

export const Dashboard = () => {
  const { data: happyData, isLoading, isError } = useQuery({
    queryKey: ["happy_data"],
    queryFn: () =>
      makeApiRequest({
        path: `/admin/happy_data`,
      }),
    retry: false,
  });

  // Display loading state
  if (isLoading) {
    return <p>Loading...</p>;
  }

  // Handle error state
  if (isError || !happyData) {
    return <p>Failed to load data.</p>;
  }

  const { total_orders, total_tickets_sold, total_revenue } = happyData.data;

  return (
<div className="p-6 space-y-6">
  <h1 className="text-2xl font-bold mb-4">Dashboard</h1>

  {/* Top boxes */}
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
    <div className="border-borderAdmin border-4 shadow-md rounded-lg p-4 text-center h-36 flex flex-col justify-center">
      <h2 className="text-xl font-semibold text-pink">Total Orders</h2>
      <p className="text-3xl font-bold">{total_orders}</p>
    </div>
    <div className="border-borderAdmin border-4 shadow-md rounded-lg p-4 text-center h-36 flex flex-col justify-center">
      <h2 className="text-xl font-semibold text-pink">Total Tickets Sold</h2>
      <p className="text-3xl font-bold">{total_tickets_sold}</p>
    </div>
    <div className="border-borderAdmin border-4 shadow-md rounded-lg p-4 text-center h-36 flex flex-col justify-center">
      <h2 className="text-xl font-semibold text-pink">Happy Revenue</h2>
      <p className="text-3xl font-bold">
        ${total_revenue.toLocaleString()}
      </p>
    </div>
  </div>
</div>

  );
};
