import { useEffect, useState } from "react";
import { useVendor } from "../../../providers/VendorContext";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Bar } from "react-chartjs-2";
import { Button } from "../../../components/reusable/Button";
import { ChartOptions, TooltipItem } from "chart.js";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { saveAs } from "file-saver";
import { EventOptions } from "../../../types/Report";
import { set } from "lodash";

interface ProductData {
  product_name: string;
  total_revenue_cents: number;
  quantity_sold: number;
}

interface EventData {
  event_id: number;
  event_name: string;
  product_data: { [product_id: number]: ProductData }; // Updated to a hash where product_id is the key
}

interface SalesByProductResponse {
  total_revenue_cents: number;
  quantity_sold_total: number;
  event_data: EventData[];
}

export const ProductSalesByEvent = () => {
  const { currentOrganization } = useVendor();
  const [searchParams, setSearchParams] = useState({
    fromDate: "",
    toDate: "",
    eventId: "",
  });
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [eventId, setEventId] = useState("");
  const [chartMetric, setChartMetric] = useState<
    "quantity_sold" | "total_revenue_cents"
  >("total_revenue_cents");

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["product-sales-by-event", currentOrganization, searchParams],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/organizations/${currentOrganization?.id}/sales_by_product`,
        params:
          searchParams.fromDate || searchParams.toDate || searchParams.eventId
            ? {
              search: {
                from_date: searchParams.fromDate,
                to_date: searchParams.toDate,
                event_id: searchParams.eventId,
              },
            }
            : {},
      }),
    enabled: !!currentOrganization,
  });

  const handleSearch = () => {
    setSearchParams({ fromDate, toDate, eventId });
  };

  const downloadProductSalesReport = () => {
    makeApiRequest({
      path: `/vendor/organizations/${currentOrganization?.id}/sales_by_product.csv`,
      params: {
        organization_id: currentOrganization?.id,
        params:
          searchParams.fromDate || searchParams.toDate || searchParams.eventId
            ? {
              search: {
                from_date: searchParams.fromDate,
                to_date: searchParams.toDate,
                event_id: searchParams.eventId,
              },
            }
            : {},
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `sales-by-product-${currentOrganization?.name}.csv`;
        saveAs(blob, filename);
      }
    });
  };

  const clearSearch = () => {
    setSearchParams({ fromDate: "", toDate: "", eventId: "" });
    setFromDate("");
    setToDate("");
    setEventId("");
  };

  const totalEventData: EventOptions[] = data?.data?.total_event_data ?? [];

  const toggleMetric = () => {
    setChartMetric((prevMetric) =>
      prevMetric === "quantity_sold" ? "total_revenue_cents" : "quantity_sold"
    );
  };

  useEffect(() => {
  }, [searchParams, refetch]);

  const totalValue =
    chartMetric === "quantity_sold"
      ? data?.data.event_data.reduce(
        (acc: number, event: EventData) =>
          acc +
          Object.values(event.product_data).reduce(
            (sum: number, product: ProductData) => sum + product.quantity_sold,
            0
          ),
        0
      )
      : data?.data.total_revenue_cents / 100;

  const chartData = data
    ? transformDataForChart(data.data, chartMetric)
    : { labels: [], datasets: [] };

  const chartOptions: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<"bar">) {
            const value = tooltipItem.raw as number;
            return chartMetric === "quantity_sold"
              ? `Quantity: ${value}`
              : `Revenue: $${value.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text:
            chartMetric === "quantity_sold"
              ? "Quantity Sold"
              : "Revenue (in dollars)",
        },
        ticks: {
          callback: function (tickValue: string | number) {
            const value =
              typeof tickValue === "number" ? tickValue : parseFloat(tickValue);
            return chartMetric === "quantity_sold" ? value : `$${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="mt-3">
      <VendorCard className="mt-3 pb-10 mb-5">
        <div className="flex flex-wrap items-end gap-4 relative">
          {(fromDate || toDate || eventId) && (
            <span
              className="absolute text-sm text-pink top-full mt-2 z-10"
              onClick={clearSearch}
              role="button"
            >
              Clear Search
            </span>
          )}
          <div className="flex flex-col flex-1 sm:max-w-[30%]">
            <label htmlFor="eventId" className="text-sm text-gray-700">
              Event
            </label>
            <select
              id="eventId"
              value={eventId}
              onChange={(e) => setEventId(e.target.value)}
              className="rounded border-gray-400 h-10 w-full"
            >
              <option value="">Select Event</option>
              {totalEventData.map((event) => (
                <option key={event.event_id} value={event.event_id}>
                  {event.event_name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col flex-1 sm:max-w-[20%]">
            <label htmlFor="fromDate" className="text-sm text-gray-700">
              From
            </label>
            <input
              type="date"
              id="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="rounded border-gray-400 h-10 w-full"
            />
          </div>
          <div className="flex flex-col flex-1 sm:max-w-[20%]">
            <label htmlFor="toDate" className="text-sm text-gray-700">
              To
            </label>
            <input
              type="date"
              id="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="rounded border-gray-400 h-10 w-full"
            />
          </div>
          <div className="flex-1 sm:max-w-[20%]">
            <Button onClick={handleSearch} variant="purple" className="w-full sm:w-auto">
              Search
            </Button>
          </div>
        </div>
      </VendorCard>


      {isLoading ? (
        <VendorCard className="mt-3">
          <span>Loading...</span>
        </VendorCard>
      ) : (
        <>
          <VendorCard>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <h2 className="text-lg font-bold pb-5">
                  Product Sales Breakdown
                </h2>
              </div>
              <h2 className="text-lg font-bold pb-5 text-green-500">
                Total:{" "}
                {chartMetric === "quantity_sold"
                  ? totalValue
                  : `$${totalValue?.toFixed(2)}`}
              </h2>
            </div>
            <div className="w-full h-96 mx-auto">
              <Bar data={chartData} options={chartOptions} />
            </div>
            <Button onClick={toggleMetric} variant="default" className="mt-5">
              Toggle to {chartMetric === "quantity_sold" ? "Revenue" : "Quantity"}
            </Button>
          </VendorCard>
          <VendorCard className="mt-3">
            <div className="flex">
              <Button
                onClick={downloadProductSalesReport}
                size="sm"
                className="mb-2 ml-auto"
              >
                <ArrowDownTrayIcon className="w-5 h-5" />
                Download CSV
              </Button>
            </div>
            <ProductSalesTable eventData={data?.data.event_data} />
          </VendorCard>
        </>
      )}
    </div>
  );
};

// Helper function to transform data for the chart
const transformDataForChart = (
  data: SalesByProductResponse,
  metric: "quantity_sold" | "total_revenue_cents"
) => {
  if (!data || !Array.isArray(data.event_data)) {
    console.error("Unexpected data structure:", data);
    return { labels: [], datasets: [] };
  }

  const eventNames = data.event_data.map((event) => event.event_name);
  const productNames = Array.from(
    new Set(
      data.event_data.flatMap((event) =>
        Object.values(event.product_data).map((product) => product.product_name)
      )
    )
  );

  const predefinedColors = [
    "#7209B7",
    "#486BFF",
    "#F72685",
    "#E4C4FA",
    "#9966FF",
    "#FF9F40",
  ];

  const generateUniqueColor = (index: number) => {
    const hue = (index * 137.508) % 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  const datasets = productNames.map((productName, index) => {
    const dataPoints = data.event_data.map((event) => {
      const product = Object.values(event.product_data).find(
        (product) => product.product_name === productName
      );
      return product
        ? metric === "total_revenue_cents"
          ? product[metric] / 100
          : product[metric]
        : 0;
    });

    const color =
      index < predefinedColors.length
        ? predefinedColors[index]
        : generateUniqueColor(index);

    return {
      label: productName,
      data: dataPoints,
      backgroundColor: color,
      fill: true,
    };
  });

  return { labels: eventNames, datasets };
};

const ProductSalesTable = ({ eventData }: { eventData: EventData[] }) => {
  const productNames = Array.from(
    new Set(
      eventData.flatMap((event) =>
        Object.values(event.product_data).map((product) => product.product_name)
      )
    )
  );

  const totalRevenuePerProduct = productNames.map((productName) =>
    eventData.reduce((acc, event) => {
      const product = Object.values(event.product_data).find(
        (p) => p.product_name === productName
      );
      return acc + (product ? product.total_revenue_cents : 0);
    }, 0)
  );

  const totalQuantityPerProduct = productNames.map((productName) =>
    eventData.reduce((acc, event) => {
      const product = Object.values(event.product_data).find(
        (p) => p.product_name === productName
      );
      return acc + (product ? product.quantity_sold : 0);
    }, 0)
  );

  const grandTotalRevenue =
    totalRevenuePerProduct.reduce((acc, revenue) => acc + revenue, 0) / 100;

  return (
    <table className="min-w-full divide-y divide-gray-200 text-sm">
      <thead>
        <tr className="text-left">
          <th>Event</th>
          {productNames.map((productName) => (
            <th key={productName}>{productName}</th>
          ))}
          <th>Total</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {eventData.map((event) => {
          const totalRevenue =
            Object.values(event.product_data).reduce(
              (acc, product) => acc + product.total_revenue_cents,
              0
            ) / 100;
          return (
            <tr key={event.event_id} className="h-11 text-gray-400">
              <td className="py-1">{event.event_name}</td>
              {productNames.map((productName) => {
                const product = Object.values(event.product_data).find(
                  (product) => product.product_name === productName
                );
                return (
                  <td key={`${event.event_id}-${productName}`}>
                    {product
                      ? `$${(product.total_revenue_cents / 100).toFixed(2)} (${product.quantity_sold
                      })`
                      : "$0.00 (0)"}
                  </td>
                );
              })}
              <td className="py-1">${totalRevenue.toFixed(2)}</td>
            </tr>
          );
        })}
        <tr className="h-11 text-gray-400 font-bold">
          <td className="py-1">Total</td>
          {productNames.map((productName, index) => (
            <td key={`total-${index}-${productName}`}>
              ${((totalRevenuePerProduct[index] || 0) / 100).toFixed(2)} (
              {totalQuantityPerProduct[index] || 0})
            </td>
          ))}
          <td className="py-1">${grandTotalRevenue.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  );
};
