
import { Fragment, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { FaCaretDown } from 'react-icons/fa';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
}

export const TextInput = ({ name, label, className, ...rest }: Props) => {
  return (
    <div className="w-full">
      <div>
        <label htmlFor="email" className={!label ? "sr-only" : "block mb-2"}>
          {label}
        </label>
        <input
          name={name}
          id={name}
          className={`h-10 block w-full rounded bg-white text-gray-500 focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400 ${className}`}
          {...rest}
        />
      </div>
    </div>
  );
};

export const TextAreaInput = ({ name, label, className, ...rest }: TextAreaProps) => {
  return (
    <div className="w-full">
      <div>
        <label htmlFor="email" className={!label ? "sr-only" : "block mb-2"}>
          {label}
        </label>
        <textarea
          name={name}
          id={name}
          {...rest}
          className={`shadow-sm resize-none block w-full rounded bg-white text-gray-500 focus:ring-purple border border-gray-300 shadow-sm placeholder:text-gray-400 ${className}`}
        />
      </div>
    </div>
  );
}


export interface Option {
  value: string;
  display: string | JSX.Element;
}

interface SelectProps {
  name: string;
  label?: string;
  options: Option[];
  className?: string;
  initialValue?: Option | null;
  onSelect?: (option: Option) => void;
}

export const SelectInput = ({ name, label, options, className, onSelect, initialValue }: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(initialValue || null);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    onSelect && onSelect(option);
  }

  return (
    <div className=''>
      <div>
        <label htmlFor={name} className={!label ? 'sr-only' : 'block mb-2'}>
          {label}
        </label>
        <Menu as="div" className="w-full">
          <div>
            <MenuButton type="button" className={`shadow-sm flex justify-between items-center px-3 h-10 w-full rounded border border-gray-300 text-gray-500 focus:ring-purple placeholder:text-gray-400 text-left ${className}`}>
              {selectedOption ? selectedOption.display : 'Select an option'}
              <ChevronDownIcon className="w-5" />
            </MenuButton>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems anchor={"bottom start"} className="w-64 min-w mt-2 bg-white rounded-md shadow-lg focus:outline-none max-h-44 overflow-auto">
              {options.map((option) => (
                <MenuItem key={option.value}>
                  {({ active }) => (
                    <button
                      type='button'
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } block px-4 py-2 text-sm text-gray-700 w-full text-left`}
                      onClick={() => handleSelect(option)}
                    >
                      {option.display}
                    </button>
                  )}
                </MenuItem>
              ))}
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};