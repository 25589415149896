import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../../utils/api";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  Table,
} from "@tanstack/react-table";
import { FormEvent, FormEventHandler, Fragment, ReactNode, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { orderBy } from "lodash";
import { Organization } from "../../../types/Organization";
import { VendorTable } from "../../../components/reusable/VendorTable";
import { Resources } from "../../../types/Resources";

export const OrgList = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState<string>("");
  const { data, refetch } = useQuery({
    queryKey: ["organizations"],
    queryFn: () =>
      makeApiRequest({
        path: "/admin/organizations",
        params: { search: { name } },
      }),
    enabled: true,
  });

  const organizations: Organization[] = data?.data;
  const columnHelper = createColumnHelper<Organization>();

  const handleSubmit = ((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name) {
      setError("Please enter a name to search");
      return;
    }
    refetch();
  });

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => (
        <Link className="text-lightPurple hover:brightness-125" to={`/admin/organizations/${info.row.original.id}`}>
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("address_1", {
      header: "Address",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("contact_phone", {
      header: "Phone",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("contact_email", {
      header: "Email",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("event_count", {
      header: "Events",
      cell: (info) => (
        <span className="block text-center">{info.getValue()}</span>
      ),
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        return (
          <div className="flex">
            <Menu as="div" className={"relative inline-block"}>
              <MenuButton className="bg-transparent flex items-center justify-center">
                <Cog6ToothIcon className="h-6 text-white/30" />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="w-40 right-0 absolute bg-dropdown border-borderAdmin border z-10 rounded-md">
                  <MenuItem
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/vendor/organizations/${props.row.original.id}`}
                    >
                      Vendor View
                    </Link>
                  </MenuItem>
                  <MenuItem
                    as={"div"}
                    className={"px-3 py-2 hover:bg-white/10"}
                  >
                    <Link
                      className="text-white/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Edit
                    </Link>
                  </MenuItem>
                  <MenuItem
                    as={"div"}
                    className={
                      "px-3 py-2 hover:bg-white/10 border-borderAdmin border-t"
                    }
                  >
                    <Link
                      className="text-pink/60 block"
                      to={`/admin/organizations/${props.row.original.id}`}
                    >
                      Delete
                    </Link>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: organizations,
    columns,
    getCoreRowModel: getCoreRowModel(),
  }) as Table<Resources>
  return (
    <>
      <div className="">
        <div className="mx-auto">
          <div className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-white/60">
                    Organizations
                  </h1>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <Link
                    to={`/admin/organizations/new`}
                    className=" -translate-y-full sm:translate-y-0 flex items-center rounded-md bg-pink px-3 py-2 text-center text-sm font-semibold text-white hover:bg-purple"
                  >
                    <PlusIcon className="h-5 mr-1" />
                    Add Organization
                  </Link>
                </div>
              </div>
              <div className="mt-4 px-6 py-8 rounded-lg border-borderAdmin border flex justify-center items-center">
                <form onSubmit={handleSubmit}>
                  <div className="flex items-center relative gap-2 justify-center">
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Organization Name"
                      className="w-72 focus:outline-purple focus:ring-0 text-white/60 bg-input/10 h-10 px-3 rounded-lg border-none"
                    />
                    <button
                      type="submit"
                      className="h-10 bg-purple rounded-lg px-10 text-white"
                    >
                      Search
                    </button>
                    <span className="text-yellow absolute left-0 text-xs -bottom-6">
                      {error}
                    </span>
                  </div>
                </form>
              </div>
              <div className="mt-8 flow-root">
                {organizations?.length > 0 ? (
                  <>
                    {/* Mobile Layout: Card */}
                    <div className="block sm:hidden space-y-4">
                    {organizations.map((org) => (
                      <div
                        key={org.id}
                        className="p-4 bg-white/5 rounded-lg border border-gray-600 shadow-sm space-y-4"
                      >
                        {/* Header Section */}
                        <div className="flex justify-between items-center">
                          <h3 className="text-lg font-semibold text-lightPurple text-center flex-1">
                            {org.name}
                          </h3>
                          <Menu as="div" className="relative">
                            <MenuButton className="p-2 border rounded bg-gray-700">
                              <Cog6ToothIcon className="h-5 w-5 text-gray-300" />
                            </MenuButton>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <MenuItems className="absolute right-0 mt-2 w-40 rounded-md shadow-lg border bg-gray-700 border-gray-700 focus:outline-none z-20">
                                <MenuItem
                                  as="div"
                                  className="px-4 py-2 hover:bg-gray-700/20 cursor-pointer"
                                >
                                  <Link
                                    className="text-gray-300"
                                    to={`/vendor/organizations/${org.id}`}
                                  >
                                    Vendor View
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  as="div"
                                  className="px-4 py-2 hover:bg-gray-700/20 cursor-pointer"
                                >
                                  <Link
                                    className="text-gray-300"
                                    to={`/admin/organizations/${org.id}`}
                                  >
                                    Edit
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  as="div"
                                  className="px-4 py-2 hover:bg-gray-700/20 cursor-pointer border-t border-gray-600"
                                >
                                  <Link
                                    className="text-pink/60"
                                    to={`/admin/organizations/${org.id}`}
                                  >
                                    Delete
                                  </Link>
                                </MenuItem>
                              </MenuItems>
                            </Transition>
                          </Menu>
                        </div>

                        {/* Details Section */}
                        <div className="space-y-2">
                          <div className="flex justify-between">
                            <span className="font-medium text-gray-300">Address:</span>
                            <span className="text-right">{org.address_1 || "N/A"}</span>
                          </div>
                          <div className="flex justify-between">
                            <span className="font-medium text-gray-300">Phone:</span>
                            <span className="text-right">{org.contact_phone || "N/A"}</span>
                          </div>
                          <div className="flex justify-between">
                            <span className="font-medium text-gray-300">Email:</span>
                            <span className="text-right">{org.contact_email || "N/A"}</span>
                          </div>
                          <div className="flex justify-between">
                            <span className="font-medium text-gray-300">Events:</span>
                            <span className="text-right">{org.event_count}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>


                    {/* Desktop Layout: VendorTable */}
                    <div className="hidden sm:block overflow-x-auto">
                      <VendorTable table={table} admin={true} />
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center h-20">
                    <span className="text-gray-400">No organizations to display</span>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
