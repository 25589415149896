import { useQuery } from "@tanstack/react-query";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { TicketTable } from "../Events/Tickets";
import { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { Spinner } from "../../../components/reusable/Spinner";
import { Pagination } from "../../../types/Pagination";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { TicketIcon } from "@heroicons/react/24/outline";
import { TextInput } from "../../../components/reusable/Form";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Link} from "react-router-dom";
import { Ticket } from "../../../types/Ticket";

export const TicketList = () => {
  const [ticketId, setTicketId] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [eventName, setEventName] = useState<string>("");
  const [saleStatus, setSaleStatus] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<{
    id?: string | null;
    event?: string | null;
    user_name?: string | null;
    sale_status?: string | null;
    page: number | null;
  }>();
  const { currentOrganization } = useVendor();
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["tickets", searchQuery, currentOrganization],
    queryFn: () =>
      makeApiRequest({
        path: `/vendor/tickets`,
        params: {
          page: searchQuery?.page,
          per_page: 50,
          organization_id: currentOrganization?.id,
          search: searchQuery,
        },
      }).then((res) => {
        const pagination: Pagination = res.data.pagination;

        return {
          data: res.data.tickets,
          pagination,
        };
      }),
    enabled:
      !!(searchQuery && searchQuery.id) ||
      !!(searchQuery && searchQuery.user_name) ||
      !!(searchQuery && searchQuery.event) ||
      (!!(searchQuery && searchQuery.sale_status) && !!currentOrganization),
  });

  const tickets = data?.data;
  const pagination: Pagination | undefined = data?.pagination;
  const handlePageChange = ({ direction }: { direction: "next" | "prev" }) => {
    if (!pagination) return;
    const nextPage =
      direction === "next" ? pagination.next_page : pagination.prev_page;
    setSearchQuery({ ...searchQuery, page: nextPage });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement> ) => {
    e.preventDefault();
    if (!ticketId && !eventName && !saleStatus && !userName) {
      setError("Please enter search criteria.");
      return;
    }
    setError("");
    const payload = { id: ticketId, event: eventName, sale_status: saleStatus, user_name: userName, page: pagination?.current_page || 1};
    console.log(payload)
    if (isEqual(payload, searchQuery)) {
      refetch();
      console.log("refetching")
      return;
    }
    setSearchQuery({
      id: ticketId,
      event: eventName,
      sale_status: saleStatus,
      user_name: userName,
      page: pagination?.current_page || 1,
    });

    const searchParams = new URLSearchParams();
    if (ticketId) searchParams.set("id", ticketId);
    if (eventName) searchParams.set("event", eventName);
    if (saleStatus) searchParams.set("sale_status", saleStatus);
    if (userName) searchParams.set("user_name", userName);
    searchParams.set("page", String(pagination?.current_page || 1));

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState(null, "", newUrl);
  };

  const downloadCSV = () => {
    makeApiRequest({
      path: `/vendor/tickets.csv`,
      params: {
        organization_id: currentOrganization?.id,
      },
    }).then((res) => {
      if (res.status === 200) {
        const CSV = res.data;
        const blob = new Blob([CSV], { type: "text/csv" });
        const filename = `tickets-${format(new Date(), "MM/dd/yyyy")}.csv`;
        saveAs(blob, filename);
      }
    });
  };
  const clearSearch = () => {
    setTicketId("");
    setEventName("");
    setSaleStatus("");
    setUserName("");
    setSearchQuery(undefined);
    const newUrl = `${window.location.pathname}`;
    window.history.pushState(null, "", newUrl);
  };
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const id = urlSearchParams.get("id");
    const user_name = urlSearchParams.get("user_name");
    const event = urlSearchParams.get("event");
    const saleStatus = urlSearchParams.get("sale_status");
    const page = Number(urlSearchParams.get("page")) || 1;

    if (id || event || saleStatus || user_name) {
      setSearchQuery({ id, event, sale_status: saleStatus, page, user_name });
      setTicketId(id || "");
      setEventName(event || "");
      setSaleStatus(saleStatus || "");
      setUserName(user_name || "");
    }
  }, []);

  return (
    <>
      <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
        <TicketIcon className="h-7 w-7" />
        Tickets
      </h1>
      <VendorCard className="mt-5">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-wrap sm:flex-nowrap items-center justify-center gap-4 w-full">
            <div className="w-full sm:w-1/4">
              <TextInput
                name="ticketId"
                value={ticketId}
                onChange={(e) => setTicketId(e.target.value)}
                placeholder="Ticket ID"
              />
            </div>
            <div className="w-full sm:w-1/4">
              <TextInput
                name="eventName"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                placeholder="Event Name"
              />
            </div>
            <div className="w-full sm:w-1/4">
              <TextInput
                name="userName"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="User Name"
              />
            </div>
            <div className="w-full sm:w-1/4">
              <select
                value={saleStatus}
                onChange={(e) => setSaleStatus(e.target.value)}
                className="h-10 px-4 rounded bg-white focus:ring-purple focus:border-purple border border-gray-300 shadow-sm placeholder:text-gray-400 w-full"
              >
                <option value="">Sale Status</option>
                <option value="sold">Sold</option>
                <option value="unsold">Unsold</option>
              </select>
            </div>
            <div className="w-full sm:w-auto">
              <button
                type="submit"
                className="h-10 bg-purple rounded-lg px-10 text-white w-full sm:w-auto"
              >
                Search
              </button>
            </div>
            {isFetching && <Spinner className="w-full sm:w-auto" />}
          </div>
          <div className="flex justify-start mt-2 text-xs gap-2">
            <span className="text-yellow">{error}</span>
            {searchQuery && (
              <span
                onClick={clearSearch}
                role="button"
                className="text-red-500 text-xs cursor-pointer"
              >
                Clear search
              </span>
            )}
          </div>
        </form>
      </VendorCard>


      {tickets ? (
        <VendorCard className="mt-5">
          <div className="hidden sm:block overflow-auto">
            {/* Table for larger screens */}
            <TicketTable tickets={tickets} />
          </div>
        
          <div className="block sm:hidden space-y-4">
            {/* Cards for mobile */}
            {tickets.map((ticket: Ticket) => (
              <div
                key={ticket.id}
                className="p-4 bg-white/5 rounded-lg border border-gray-400 shadow-sm "
              >
                <div className="flex justify-between items-center">
                  <span className="font-bold text-gray-500">Ticket ID:</span>
                  <Link
                    to={`/vendor/organizations/${currentOrganization?.id}/tickets/${ticket.id}`}
                    className="text-lightPurple hover:brightness-125"
                  >
                    {ticket.id}
                  </Link>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-bold text-gray-500">Event:</span>
                  <Link
                    to={`/vendor/organizations/${currentOrganization?.id}/events/${ticket.event?.id}`}
                    className="text-lightPurple hover:brightness-125"
                  >
                    {ticket.event?.name}
                  </Link>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-bold text-gray-500">Tier:</span>
                  <span className="text-gray-400">{ticket.tier.name}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-bold text-gray-500">Section:</span>
                  <span className="text-gray-400">{ticket.section.name}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-bold text-gray-500">Sold To:</span>
                  <Link
                    to={`/vendor/organizations/${currentOrganization?.id}/customers/${ticket.user?.id}`}
                    className="text-lightPurple hover:brightness-125"
                  >
                    {ticket.user?.first_name} {ticket.user?.last_name}
                  </Link>
                </div>
                <div className="flex justify-between items-center">
                  <span className="font-bold text-gray-500">Price:</span>
                  <span className="text-gray-400">
                    {ticket.price_level ? `$${(ticket.price_level.price_cents / 100).toFixed(2)}` : ""}
                  </span>

                </div>
                <div className="flex justify-between items-center">
                  <span className="font-bold text-gray-500">Sold At:</span>
                  <span className="text-gray-400">{ticket.sold_at}</span>
                </div>
               
              </div>
            ))}
          </div>
        </VendorCard>
      
      
      ) : (
        <VendorCard className="mt-5">
          <div className="flex justify-center items-center h-10">
            <span>
              <h1 className="font-medium text-gray-600">
                {searchQuery?.id || searchQuery?.event
                  ? "No tickets found"
                  : "Use the search fields above to find tickets"}
              </h1>
            </span>
          </div>
        </VendorCard>
      )}
    </>
  );
};
