import React, { useState } from "react";

export const HappyTooltip = ({
  children,
  content,
  anchor,
}: {
  children: React.ReactNode;
  content: string;
  anchor?: "top" | "bottom" | "left" | "right";
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const anchorClasses = {
    top: "bottom-full mb-2 left-1/2 transform -translate-x-1/2",
    bottom: "top-full mt-2 left-1/2 transform -translate-x-1/2",
    left: "right-full mr-2 top-1/2 transform -translate-y-1/2",
    right: "left-full ml-2 top-1/2 transform -translate-y-1/2",
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div
          className={`absolute z-10 px-2 py-1 bg-gray-700 text-white text-xs rounded shadow-md w-72 whitespace-normal break-words ${
            anchorClasses[anchor || "top"]
          }`}
        >
          {content}
        </div>
      )}
    </div>
  );
};
