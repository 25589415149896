import { Link, useParams } from "react-router-dom";
import { makeApiRequest } from "../../../utils/api";
import { useQuery } from "@tanstack/react-query";
import { Order, OrderItem } from "../../../types/Order";
import { format } from "date-fns";
import { formatCentsToDollars } from "../../../utils/currency";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { HappyEvent } from "../../../types/Event";
import { useVendor } from "../../../providers/VendorContext";
import { CurrencyDollarIcon, LinkIcon } from "@heroicons/react/24/solid";
import { useAuth } from "../../../providers/AuthContext";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { Button } from "../../../components/reusable/Button";
import { useState } from "react";
import { Modal } from "../../../components/reusable/Modal";
import { useNotification } from "../../../providers/NotificationContext";
import { classNames } from "../../../utils/styles";
import { Payment } from "../../../types/Payment";
import {
  ChevronDoubleLeftIcon,
} from "@heroicons/react/24/outline";
import { User } from "../../../types/User";
import { Spinner } from "../../../components/reusable/Spinner";
export const ShowOrder = () => {
  const { showSuccess, showError } = useNotification();
  const [refundAmount, setRefundAmount] = useState<number>(0);
  const [refundNotes, setRefundNotes] = useState<string>("");
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const { user } = useAuth();
  const { currentOrganization } = useVendor();
  const params = useParams();
  const { data, refetch: refetchOrders } = useQuery({
    queryKey: ["orders", params.id, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/orders/${params.id}`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!(params && params.id) && !!currentOrganization,
  });

  const order: Order = data?.data;
  const submitRefund = () => {
    setRefundLoading(true);
    const refundAmountInCents = Math.round(refundAmount * 100);
    makeApiRequest({
      path: `/vendor/orders/${order.id}/refund`,
      method: "POST",
      params: {
        organization_id: currentOrganization?.id,
        refund: {
          amount: refundAmountInCents,
          notes: refundNotes,
        },
      },
    })
      .then((res) => {
        if (res.status === 200) {
          refetchOrders();
          setShowRefundModal(false);
          showSuccess("Refund submitted successfully");
          setRefundAmount(0);
          setRefundNotes("");
          setRefundLoading(false);
        }
      })
      .catch((err) => {
        const errorMessage = err.response.data.message;
        showError(errorMessage);
        setRefundLoading(false);
      });
  };

  const handleToggleFullRefund = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const totalInDollars = Math.abs(order.price_info.base / 100);
      setRefundAmount(totalInDollars);
    } else {
      setRefundAmount(0);
    }
  };

  if (!order) {
    return null;
  }
  return (
    <div>
      <Link
        className="flex items-center gap-2 text-lightPurple hover:brightness-125"
        to={`/vendor/organizations/${currentOrganization?.id}/orders`}
      >
        <ChevronDoubleLeftIcon className="w-5 h-5" />
        <span>Back to Orders</span>
      </Link>
      <div className="flex flex-wrap sm:flex-nowrap justify-between items-start mt-5 gap-4">
        <div className="w-full sm:w-3/4">
          <h1 className="text-xl sm:text-2xl text-gray-500 font-bold">
            {order.user
              ? `${order.user?.first_name} ${order.user?.last_name} - Order #${order.id}`
              : `Order #${order.id}`}
          </h1>
          <span className="block text-sm sm:text-base">
            Created: {order.created_at}
          </span>
          {user?.admin && order.sale_payment && (
            <span className="flex flex-wrap gap-1 items-center text-sm sm:text-base mt-2">
              <span className="whitespace-nowrap">Stripe Payment:</span>
              <Link
                to={`${import.meta.env.VITE_STRIPE_PAYMENT_URL}/${order.sale_payment?.stripe_payment_intent_id}`}
                target="_blank"
                className="text-lightPurple hover:brightness-125 flex items-center gap-1"
              >
                {order.sale_payment?.stripe_payment_intent_id}
                <LinkIcon className="w-4 h-4" />
              </Link>
            </span>
          )}
        </div>
        <div className="w-full sm:w-1/4 text-left sm:text-right">
          {order.price_info.total > 0 && new Date(order.event.end_date) >= new Date() && (
            <Button onClick={() => setShowRefundModal(true)} className="w-full sm:w-auto">
              Refund Order
            </Button>
          )}
        </div>
      </div>

      <VendorCard className="mt-5 text-sm">
        <div className="flex justify-between">
          <div>
            <h3 className="text-base text-gray-500 font-bold mb-2">
              User Details
            </h3>
            {order.user ? (
              <>
                <span className="block">
                  <span className="text-gray-400 font-bold text-xs">Name:</span>{" "}
                  <Link
                    className="text-lightPurple"
                    to={`/vendor/organizations/${currentOrganization?.id}/customers/${order.user.id}`}
                  >
                    {order.user.first_name} {order.user.last_name}
                  </Link>
                </span>
                <span className="block">
                  <span className="text-gray-400 font-bold text-xs">
                    User ID:
                  </span>{" "}
                  {order.user.id}
                </span>
                <span className="block">
                  <span className="text-gray-400 font-bold text-xs">
                    Email:
                  </span>{" "}
                  {order.user.email}
                </span>
                <span className="block">
                  <span className="text-gray-400 font-bold text-xs">
                    Phone:
                  </span>{" "}
                  {order.user.phone_number}
                </span>
              </>
            ) : (
              <span className="block text-gray-400 font-bold text-xs">
                No user attached
              </span>
            )}
          </div>
          <div className="w-1/3 items-center text-center flex flex-col gap-2">
            <span className="text-xl">Order Total</span>
            <span className="text-green-400 font-bold text-4xl">
              {formatCentsToDollars(order.price_info.total)}
            </span>
            {order.refunds.length > 0 ? (
              <>
                <span className="block mt-2 text-sm text-center font-bold">
                  Refund Total
                </span>
                <span className="block text-lg text-center text-red-400">
                  {formatCentsToDollars(order.price_info.refunded)}
                </span>
              </>
            ) : null}
          </div>
        </div>
        <div className="my-10 p-3 border-gray-200 rounded-lg border shadow-sm bg-gray-100">
          <span className="text-base block text-center text-gray-500 font-bold mb-2">
            Price Breakdown
          </span>
          <div className="flex gap-4 mt-2 w-full justify-center">
            <span>
              <span className="text-gray-400 font-bold text-xs">
                Base Price:
              </span>{" "}
              {formatCentsToDollars(order.price_info.base)}
            </span>
            <span>
              <span className="text-gray-400 font-bold text-xs">
                Processing Fee:
              </span>{" "}
              {formatCentsToDollars(order.price_info.processing_fee)}
            </span>
            <span>
              <span className="text-gray-400 font-bold text-xs">
                Happy Fee:
              </span>{" "}
              {formatCentsToDollars(order.price_info.happy_fee)}
            </span>
            <span>
              <span className="text-gray-400 font-bold text-xs">Tax:</span>{" "}
              {formatCentsToDollars(order.price_info.tax)}
            </span>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="text-base font-bold mb-2">
            Items{" "}
            <span className="text-xs font-normal text-gray-400">
              ({order.order_items.length} {order.order_items.length === 1 ? 'item' : 'items'})
            </span>
          </h3>
          <OrderItemTable event={order.event} orderItems={order.order_items} />
        </div>

        {order.payments?.length > 0 ? (
          <>
            <h3 className="text-base font-bold mt-5 mb-2">Payments</h3>
            <PaymentTable payments={order.payments} />
          </>
        ) : null}


        {order.form_submission && (
          <> 
            <div className="border border-gray-300 rounded-lg p-4 mt-8">
              <h3 className="text-lg font-bold text-gray-500">
                {order.form_submission.vendor_form_name || "Form Submission Details"}
              </h3>
              <div >
                {order.form_submission.answers.map((answer) => (
                  <div
                    key={answer.id}
                    className="border-b p-4 hover:bg-gray-100 transition-colors"
                  >
                    <span className="block text-sm font-bold text-gray-600 mb-2">
                      {answer.form_field_content}
                    </span>
                    <span className="block text-base text-gray-800">
                      {answer.content || "N/A"}
                    </span>
                  </div>
                ))}
              </div>

            </div>
          </>
        )}
      </VendorCard>

      <Modal
        title={"Refund Order"}
        isOpen={showRefundModal}
        onRequestClose={() => setShowRefundModal(false)}
      >
        <div>
          <div className="flex flex-col gap-3">
            <div className="relative flex items-center gap-4">
              <CurrencyDollarIcon className="w-6 h-6 text-gray-400 absolute top-0 bottom-0 left-2 m-auto" />
              <input
                value={refundAmount}
                onChange={(e) => setRefundAmount(parseFloat(e.target.value))}
                placeholder="Refund Amount"
                type="number"
                min={0}
                max={order.price_info.base / 100}
                step={0.01}
                name="refund_amount"
                className="pl-10 h-10 border-gray-200 rounded-lg"
              />
              <div>
                <label
                  className={classNames(
                    "px-4 py-2 rounded-xl border-gray-300 border cursor-pointer text-sm",
                    refundAmount === order.price_info.total / 100
                      ? "bg-green-400 text-white border-none"
                      : "bg-white"
                  )}
                  htmlFor="full-refund"
                >
                  Full Refund
                </label>
                <input
                  onChange={handleToggleFullRefund}
                  id="full-refund"
                  type="checkbox"
                  name="full_refund"
                  className="hidden h-4 w-4 border-gray-300 cursor-pointer"
                />
              </div>
            </div>
            <textarea
              value={refundNotes}
              onChange={(e) => setRefundNotes(e.target.value)}
              placeholder="Refund notes..."
              rows={3}
              name="notes"
              className="rounded-lg border-gray-200"
            />
            <Button
              className="h-12 relative"
              disabled={refundLoading}
              onClick={submitRefund}
              type="submit"
            >
              <span>Submit Refund</span>
              {refundLoading && (
                <Spinner className="absolute right-4 top-0 bottom-0 left-0 m-auto" />
              )}
            </Button>
          </div>
        </div>
      </Modal>
      
    </div>
  );
};

export const OrderItemTable = ({
  event,
  orderItems,
}: {
  event: HappyEvent;
  orderItems: OrderItem[];
}) => {
  const { currentOrganization } = useVendor();

  return (
    <>
      {/* Table for larger screens */}
      <div className="hidden sm:block">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="text-gray-500 text-sm">
              <th className="text-left">Item ID</th>
              <th className="text-left">Event</th>
              <th className="text-left">Item</th>
              <th className="text-left">UID</th>
              <th className="text-left">Price</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {orderItems.map((item) => (
              <tr className="h-11 text-sm" key={item.id}>
                <td className="py-1 text-gray-400">{item.id}</td>
                <td className="py-1 text-gray-400">
                  <Link
                    className="text-lightPurple hover:brightness-125"
                    to={`/vendor/organizations/${currentOrganization?.id}/events/${event.id}`}
                  >
                    {event.name}
                  </Link>
                </td>
                <td className="py-1 text-gray-400">
                  {item.itemable_type === "Ticket" ? (
                    <Link
                      className="text-lightPurple hover:brightness-125"
                      to={`/vendor/organizations/${currentOrganization?.id}/tickets/${item.itemable_id}`}
                    >
                      {item.object_name}
                    </Link>
                  ) : (
                    <span>{item.object_name}</span>
                  )}
                </td>
                <td className="py-1 text-gray-400">{item.ticket_info?.uid}</td>
                <td className="py-1 text-gray-400">
                  ${((item.price_cents || 0) / 100).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Card layout for mobile screens */}
      <div className="block sm:hidden space-y-4">
        {orderItems.map((item) => (
          <div
            key={item.id}
            className="p-4 bg-white shadow rounded-md border border-gray-300"
          >
            <div className="flex justify-between">
              <span className="font-medium text-gray-500">Item ID:</span>
              <span className="text-gray-700">{item.id}</span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="font-medium text-gray-500">Event:</span>
              <Link
                className="text-lightPurple hover:brightness-125"
                to={`/vendor/organizations/${currentOrganization?.id}/events/${event.id}`}
              >
                {event.name}
              </Link>
            </div>
            <div className="flex justify-between mt-2">
              <span className="font-medium text-gray-500">Item:</span>
              {item.itemable_type === "Ticket" ? (
                <Link
                  className="text-lightPurple hover:brightness-125"
                  to={`/vendor/organizations/${currentOrganization?.id}/tickets/${item.itemable_id}`}
                >
                  {item.object_name}
                </Link>
              ) : (
                <span className="text-gray-700">{item.object_name}</span>
              )}
            </div>
            <div className="flex justify-between mt-2">
              <span className="font-medium text-gray-500">UID:</span>
              <span className="text-gray-700">{item.ticket_info?.uid}</span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="font-medium text-gray-500">Price:</span>
              <span className="text-gray-700">
                ${((item.price_cents || 0) / 100).toFixed(2)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};


export const PaymentTable = ({ payments }: { payments: Payment[] }) => {
  const { currentOrganization } = useVendor();

  return (
    <>
      {/* Table layout for larger screens */}
      <div className="hidden sm:block">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="text-gray-500 text-sm">
              <th className="text-left">ID</th>
              <th className="text-left">Event</th>
              <th className="text-left">Order</th>
              <th className="text-left">User</th>
              <th className="text-left">Payment Type</th>
              <th className="text-left">Amount</th>
              <th className="text-left">Created</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {payments.map((payment) => (
              <tr className="h-11 text-sm" key={payment.id}>
                <td className="py-1 text-gray-400">
                  <Link
                    className="text-lightPurple hover:brightness-125"
                    to={`/vendor/organizations/${currentOrganization?.id}/payments/${payment.id}`}
                  >
                    {payment.id}
                  </Link>
                </td>
                <td className="py-1 text-gray-400">
                  <Link
                    className="text-lightPurple hover:brightness-125"
                    to={`/vendor/organizations/${currentOrganization?.id}/events/${payment.event?.id}`}
                  >
                    {payment.event?.name}
                  </Link>
                </td>
                <td className="py-1 text-gray-400">
                  <Link
                    className="text-lightPurple hover:brightness-125"
                    to={`/vendor/organizations/${currentOrganization?.id}/orders/${payment.order?.id}`}
                  >
                    #{payment.order?.id}
                  </Link>
                </td>
                <td className="py-1 text-gray-400">
                  <Link
                    className="text-lightPurple hover:brightness-125"
                    to={`/vendor/organizations/${currentOrganization?.id}/customers/${payment.user?.id}`}
                  >
                    {payment.user?.first_name} {payment.user?.last_name}
                  </Link>
                </td>
                <td className="py-1 text-gray-400">{payment.payment_type}</td>
                <td
                  className={`py-1 ${
                    payment.payment_type === "Sale"
                      ? "text-green-400"
                      : "text-red-400"
                  }`}
                >
                  ${((payment.amount_cents || 0) / 100).toFixed(2)}
                </td>
                <td className="py-1 text-gray-400">{payment.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Card layout for mobile screens */}
      <div className="block sm:hidden space-y-4">
        {payments.map((payment) => (
          <div
            key={payment.id}
            className="p-4 bg-white shadow rounded-md border border-gray-300 space-y-2"
          >
            <div className="flex justify-between">
              <span className="font-medium text-gray-500">ID:</span>
              <Link
                className="text-lightPurple hover:brightness-125"
                to={`/vendor/organizations/${currentOrganization?.id}/payments/${payment.id}`}
              >
                {payment.id}
              </Link>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-500">Event:</span>
              <Link
                className="text-lightPurple hover:brightness-125"
                to={`/vendor/organizations/${currentOrganization?.id}/events/${payment.event?.id}`}
              >
                {payment.event?.name}
              </Link>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-500">Order:</span>
              <Link
                className="text-lightPurple hover:brightness-125"
                to={`/vendor/organizations/${currentOrganization?.id}/orders/${payment.order?.id}`}
              >
                #{payment.order?.id}
              </Link>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-500">User:</span>
              <Link
                className="text-lightPurple hover:brightness-125"
                to={`/vendor/organizations/${currentOrganization?.id}/customers/${payment.user?.id}`}
              >
                {payment.user?.first_name} {payment.user?.last_name}
              </Link>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-500">Payment Type:</span>
              <span className="text-gray-700">{payment.payment_type}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-500">Amount:</span>
              <span
                className={`${
                  payment.payment_type === "Sale"
                    ? "text-green-400"
                    : "text-red-400"
                }`}
              >
                ${((payment.amount_cents || 0) / 100).toFixed(2)}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium text-gray-500">Created:</span>
              <span className="text-gray-700">{payment.created_at}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

