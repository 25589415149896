import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, TooltipItem } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useVendor } from '../../../../providers/VendorContext';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { makeApiRequest } from '../../../../utils/api';
import { VendorCard } from '../../../../components/reusable/VendorCard';
import { formatCentsToDollars, formatIntToReadableString } from '../../../../utils/currency';

ChartJS.register(ArcElement, Tooltip, Legend);

export const SalesByChannel = () => {
  const { currentOrganization } = useVendor();
  const params = useParams();
  const { data } = useQuery({
    queryKey: ["event_sales_by_channel", params, currentOrganization],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/events/${params.id}/sales_by_channel`,
        params: { organization_id: currentOrganization?.id },
      });
    },
    enabled: !!params.id && !!currentOrganization,
  });

  const reportData = data?.data;
  const webRevenue = reportData?.web;
  const boxOfficeRevenue = reportData?.box_office;

  const pieData = {
    labels: ['Web', 'Box Office'],
    datasets: [
      {
        label: 'Revenue',
        data: [webRevenue, boxOfficeRevenue],
        backgroundColor: ['#f72585', '#4361ee'],
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: TooltipItem<'pie'>) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += formatCentsToDollars(context.parsed);
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div className='w-full flex justify-center gap-4 my-5 py-5'>
      <VendorCard className='w-1/2 p-24 pt-4'>
        <Pie options={options} data={pieData} />
      </VendorCard>
      <div className='flex flex-col w-1/2 gap-5'>
        <VendorCard className='w-full py-10 flex flex-col items-center justify-center grow'>
          <span className='text-3xl text-lightPurple font-bold'>
            {formatCentsToDollars(webRevenue)}
          </span>
          <span>
            Web Revenue
          </span>
        </VendorCard>
        <VendorCard className='w-full py-10 flex flex-col items-center justify-center grow'>
          <span className='text-3xl text-lightPurple font-bold'>
            {formatCentsToDollars(boxOfficeRevenue)}
          </span>
          <span>
            Box Office Revenue
          </span>
        </VendorCard>
      </div>
    </div>
  );
};
