import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../utils/api";
import { useAuth } from "../../providers/AuthContext";
import { Organization } from "../../types/Organization";
import { useVendor } from "../../providers/VendorContext";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { AdminOrganization } from "../../types/User";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { useNavigate } from "react-router-dom";

import { User } from "../../types/User";

export const OrgSelection = ({ user }: { user: User }) => {
  const { currentOrganization, setCurrentOrganization } = useVendor();
  const navigate = useNavigate();
  const handleOrgSelect = (org: AdminOrganization) => () => {
    makeApiRequest({
      path: `/vendor/organizations/${org.id}`,
      method: "GET",
    }).then((response) => {
      setCurrentOrganization(response.data);
      navigate(
        `/vendor/organizations/${currentOrganization?.id}`
      )
  
    }).catch((error) => {
      console.error("Failed to fetch organization details:", error);
    });
    
  }

 

  const admin_organizations = user.admin_organizations;

  if (admin_organizations?.length > 1) {
    return (
      <Menu as="div" className={"relative"}>
        <MenuButton className={"lg:w-48 flex text-xs justify-between border rounded-lg border-gray-300 bg-white h-8 items-center px-4"}>
          <span>{currentOrganization?.name || "Select an Organization"}</span>
          <ChevronDownIcon className="w-4" />
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
            <MenuItems className="flex flex-col absolute right-0 z-50 mt-2.5 lg:w-48 origin-top-right rounded-md bg-gray-50 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            {admin_organizations?.map((org) => (
              <MenuItem key={org.id}>
              {
                <button
                onClick={handleOrgSelect(org)}
                className={`flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                >
                {org.name}
                </button>
              }
              </MenuItem>
            ))}
            </MenuItems>
        </Transition>
      </Menu>
    );
  }

  if (admin_organizations?.length === 1) {
    return (
      <span className="font-sans text-xs ml-4 px-2 py-1 rounded-lg inline-flex bg-lightPurple/30">
        <span className="text-lightPurple">
          {currentOrganization?.name}
        </span>
      </span>
    );
  }

  return null

};
