import { useQuery } from "@tanstack/react-query";
import { VendorCard } from "../../../components/reusable/VendorCard";
import { useVendor } from "../../../providers/VendorContext";
import { makeApiRequest } from "../../../utils/api";
import { useState } from "react";
import { isEqual } from "lodash";
import DatePicker from "react-datepicker";
import { OrdersTable } from "../Events/EventDetails";
import { ListBulletIcon } from "@heroicons/react/24/outline";
import { TextInput } from "../../../components/reusable/Form";
type SearchFormQuery = {
  id: string;
  eventName: string;
  from: Date | null;
  to: Date | null;
};
export const OrderList = () => {
  const { currentOrganization } = useVendor();
  const [orderId, setOrderId] = useState<string>("");
  const [eventName, setEventName] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [error, setError] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<SearchFormQuery>();
  const { data: ordersData, refetch } = useQuery({
    queryKey: ["orders", searchQuery],
    queryFn: () => {
      return makeApiRequest({
        path: `/vendor/orders`,
        params: {
          organization_id: currentOrganization?.id,
          search: searchQuery,
        },
      });
    },
    enabled: !!currentOrganization,
  });

  const handleClearSearch = () => {
    setError("");
    setOrderId("");
    setEventName("");
    setDateFrom(null);
    setDateTo(null);
    setSearchQuery(undefined);
  };

  const handleSubmit = () => {
    setError("");
    const payload: SearchFormQuery = {
      id: orderId,
      eventName: eventName,
      to: dateTo,
      from: dateFrom,
    };
    if (isEqual(payload, searchQuery)) {
      refetch();
      return;
    }
    setSearchQuery(payload);
  };

  const orders = ordersData?.data;
  
  return (
    <>
      <h1 className="text-2xl font-semibold leading-6 text-gray-500 flex items-center gap-3">
        <ListBulletIcon className="h-7 w-7" />
        Orders
      </h1>
      <VendorCard className="mt-4 mb-5 py-4 w-full">
        <div className="flex flex-col lg:flex-row w-full gap-4 items-start">

          {/* Order ID and Clear Search */}
          <div className="flex flex-col w-full lg:w-1/4">
          <label className="text-xs text-gray-500 mb-1">Order #</label>

            <TextInput
              name="orderId"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              placeholder="Order ID"
              className="h-10 w-full rounded border border-gray-300 focus:ring-purple focus:border-purple"
            />
            {(orderId || eventName || dateFrom || dateTo) && (
              <button
                className="text-pink hover:brightness-125 text-xs mt-2 self-start"
                onClick={handleClearSearch}
              >
                Clear Search
              </button>
            )}
            <span className="text-yellow text-xs mt-1">
              {error}
            </span>
          </div>

          {/* Event Name */}
          <div className="flex flex-col w-full lg:w-1/4">
          <label className="text-xs text-gray-500 mb-1">From</label>

            <TextInput
              name="eventName"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              placeholder="Event Name"
              className="h-10 w-full rounded border border-gray-300 focus:ring-purple focus:border-purple"
            />
          </div>

          {/* From Date */}
          <div className="flex flex-col w-full lg:w-1/4">
            <label className="text-xs text-gray-500 mb-1">From</label>
            <DatePicker
              placeholderText="Start Date"
              selected={dateFrom}
              onChange={(date) => setDateFrom(date as Date)}
              className="h-10 w-full rounded border border-gray-300 focus:ring-purple focus:border-purple placeholder:text-gray-400"
            />
          </div>

          {/* To Date */}
          <div className="flex flex-col w-full lg:w-1/4">
            <label className="text-xs text-gray-500 mb-1">To</label>
            <DatePicker
              placeholderText="End Date"
              selected={dateTo}
              onChange={(date) => setDateTo(date as Date)}
              className="h-10 w-full rounded border border-gray-300 focus:ring-purple focus:border-purple placeholder:text-gray-400"
            />
          </div>

          {/* Search Button */}
          <div className="flex flex-col w-full lg:w-auto mt-4 lg:mt-0">
            <button
              className="h-10 bg-purple rounded-lg px-4 text-white w-full lg:w-auto lg:mt-5 md:mt-5"
              onClick={handleSubmit}
            >
              Search
            </button>
          </div>

        </div>
      </VendorCard>

      {orders && orders.length > 0 ? (
        <>
          <VendorCard>
            <OrdersTable orders={orders} />
          </VendorCard>
        </>
      ) : (
        <VendorCard>
          <div className="text-center font-medium text-gray-600">No orders yet</div>
        </VendorCard>
      )}
    </>
  );
};
